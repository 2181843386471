.Appheader {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 300px;
    font-size: calc(10px + 2vmin);
    /* color: black; */
  }
  .App {
    text-align: center;
  } 
/*------ request-page ---*/

.requestformpage .adminBtn{
    background: #4CAF50 !important;
}

.requestformpage .refundBtn{
    background: #556cd6 !important;
}

.requestformpage{
    background:rgb(174, 227, 253) !important;
}
.requestformpage .MuiPaper-root{
    margin-bottom: 60px !important;
}
.requestformpage .MuiPaper-root{
    margin-bottom: 60px !important;
    
}

.requestformpage .saveforLetterBtn{
    background: #4CAF50 !important;
}
.requestformpage .saveforLetterBtn{
    background: #4CAF50 !important;
    color:#000 !important;
}

.requestformpage .sendPayment{
    background: #9c27b0 !important;
}


 
.top-header {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-top: 15px;
}
.logout-img{
    width:18px;
    height: 18px;
    margin-left:0px;
    margin-top:6px;
    float: right;
}
.topheader {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-top: 15px;
}
.logoutimg{
    width:18px;
    height: 18px;
    margin-left:0px;
    margin-top:6px;
    float: right;
}
.MuiButton-containedSecondary{
    background-color: #4CAF50 !important;
  }
  .containerControl{
      max-width:1140px;
      width:100%;
    //   width: 87%;
      height: 100%;
      margin-bottom: 50px;
    //   margin: 0px 60px !important;
    margin: 0px;
  }
  .intakePageHeading{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 0px;
  }

.requestformpageLoading{
    // background-color: #aee3fd !important;
    padding : 0px !important;
    margin-top: 0px !important;
    width: 87% !important;
    
}
