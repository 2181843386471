.errorPageGeneralStyle { text-align: justify; }

@media only screen and (max-width: 900px) {
  .errorPageStyle {
    max-width: 90%;
  }
}

@media only screen and (min-width: 901px) {
  .errorPageStyle {
    max-width: 50%;
    text-align: center;
  }
}