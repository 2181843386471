.custom_div .col{
    display: inline-block;
  }
  .custom_div .right-align{
    text-align: right;
  }
  .float_right{
    float:right;
  }
  .table_responsive{
    overflow: auto;
  }
  .custom_table table tbody tr td:nth-child(9) {
    min-width: 250px;
  }
  .custom_table table tbody tr td:last-child {
    white-space: nowrap;
  }
  .custom_table table tbody tr td:last-child i {
    vertical-align:bottom;
  }
  .custom_table table tbody tr td:last-child a {
    text-decoration: none;
  }
  .red.darken_2 {
    background-color: #D32F2F !important;
  }
  .custom_table .btn{
    color: #fff;
    text-align: center;
    letter-spacing: .5px;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    
  }

  .float_left{
    float: left;
  }
  .download_div{    
    padding-top: 30px !important;
  } 
  .download_icon{
      vertical-align: middle;
  }
  .download_div a{
      color:#4e48b3;
      font-size: 20px;    
  }
  .float_right{
      float:right;
  }

  .clearfix{
    clear: both;
  }
  .nav_width_65{
    width: 100%;
  }

.tpMainContentB .input_+field label{
    font-size: 22px;
    color: #000;
}
.right_align{
	text-align: right;
}
.tpDashboardM {
	& > .s2 {
		padding: 0; 
	}
	.download_div a {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}
.App_header {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */
}
.dashboard_panel1{
  background :#f7f7f7;
  width: 100%;
}
.dashboard_total{
  background : palegreen;
}
.dashboard_panel1_2{
  background: gainsboro;
}
.dashboard_panel1_1 {
  
  background :#52b152;
}
.total_span{
  text-decoration: solid
}
.card{
  width: 100%;
}
.customCard {
  width: 100%;
}
.customCard .cardContent{
  padding: 0;
  width: 100%
}
.customCard .cardContent h4{
  background: #0f7bc1;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
}
.customCard .cardContent .cardContentBox{
  padding: 1rem;
}
.cardContentBox .totalGray, .totalYellow,.cardContentBox .totalGreen{
  padding: 1.3rem;
  text-align: center;
  font-weight: bold ;
  margin: 1rem 0;
  color: #fff;
}
.totalGray{
  background: #888;
}
.totalYellow{
  background:#dbde2585;
  font-weight: bold !important;
}
.totalGreen{
  background: #52b152;
}
.Underline{
  color :blue;
  cursor:pointer
}

.Dashboard_cardContentBoxTnx{ overflow:scroll !important; height: 200px;}
.rightAlign {
    float: right;
}

@media screen and (max-width:767px){
  p.MuiTypography-root span {
      margin: 0 !important;
      float: none;
      display: block;
  }
  .userName .MuiPaper-root {
    width: 30%;
    right: 0;
    max-width: 200px;
    top: 45px;
  }
  .no-display {
    display: none;
  }
  .totalGray, .totalYellow, .totalGreen{
    text-align: left !important;
  }
}