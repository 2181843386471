.App_header {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* color: black; */
  }
  .payBtn{
    color: white !important;
    background:green !important;
    border-radius: 50px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
   }
.invoiceDetail{
  margin-top:2.5rem;
}
.cardNum{
  width: 34rem;
    height: 3rem;
    letter-spacing: 1.65rem;
}
.expDate{
  width: 10rem;
  height: 3.3rem;
  padding: 0px 3rem;
}
.cardCvv{
  width: 5rem;
  height: 3.3rem;
  padding: 0px 1rem;
}
.viewInvoice{
  border-radius: 50px !important;
  width:100%;
}

@media only screen and (max-width:480px){
  .cardNum{
  
    letter-spacing:0.4rem;
}
}
