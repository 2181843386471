	body .login {
	  background:url('./../../assets/images/loginImage.jpg');
	  width: 100%;
      height: 100%;
      position: absolute;
	}
	.paper {
	   margin-top: 100px;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   background-color:white;
	   padding:20px;
	   border-radius:5px;
	}
    .avatar {
	    margin: 1px;
    }
    .form {
		width: 100%;
		margin-top: 1px;
	}
	@media (max-width: 1024px) {
		.service_container > div > div {
			width: 100%;
			display: inline-block !important;
		}
		.service_container > div > div > div {
			max-width: 100%;
			margin-left: 30px;
		}
	  }
	