@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap);
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
    font-family:'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f1f1;
}

[class*='Mui']{
  font-family: inherit !important;
}
[class*='Mui'] .material-icons{
  font-family: 'Material Icons' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace;
}

#root {
  position: relative;
  min-height: 100%;
  padding-bottom: 37px;
}
.wrapper {
  display: flex;
}
.rightPanel{
  width: 100%;
}


.sidebar{
  width: 16rem;
  background: #2F2F2F;
  height: 100vh;
  position: fixed;
}

body.toggle .sidebar{
  width: 54px;
}

body.toggle .sidebar .logoBox img{
  width: 50px;
  height: 50px;
}

.left-control{  
  width: calc(100% - 40px);
}



.sidebar,
body.toggle .sidebar .logoBox img{
  transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}


.logoBox{
  text-align: center;
  padding: 15px 0;
}

.customHeader header{
  background: #117CC1;
}

.username > span{
  color: #fff;
}
.contentPart{
  padding: 1rem;
}
.MuiTypography-gutterBottom.errorCard{
  color: red !important;
}
labels.errorCard{
  color: red !important;
}

.noteMsg{
 
  color: #fff;
    background: #0f7bc1;
    padding: 7px;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
}
.errorMsg{
 
  color: #fff;
    background:#e45e5e;
    padding: 7px;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
}
.successMsg{
 
  color: #fff;
    background:#4dbf4d;
    padding: 7px;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
}
.noteMsg span, .errorMsg span, .successMsg span{ font-weight: 600;}




.Redcursor{
  cursor: no-drop;
}
.ml2{
  margin-left:2rem; 
}
.tabCustom{
  margin-top: -1.5rem,
}
.profile-logo  {
  width: 35px;
  flex-basis: 35px;
  height:35px;
  border-radius: 50%;
}
.customHeader .MuiToolbar-regular {
  min-height: 44px;
  height: 44px;
}
.tp-tabcustom > .MuiTypography-root > .MuiBox-root  {
  padding: 24px 15px;
}

header.customHeader{
  position: relative;
  z-index: 1;
}
.react-form-builder .react-form-builder-toolbar{
  margin-top:0px !important
} 
.Dashboard_cardContentBoxTnx {
  overflow: auto !important;
  max-height: 400px;
}

.terminal-container-term ._loading_overlay_wrapper .MuiGrid-item{
  text-align: center;
  max-width: 100%;
}

.imgtermLogo {
  margin-left: 0 !important;
  width: 10%;
  margin: 0 auto;
  text-align: center;
}
.MuiInputBase-root {
  z-index: 0;
}


@media (max-width: 1440px) {
  .Dashboard_cardContentBoxTnx {
    max-height: 300px;
  }
}
.terminal-container .terminal-title {
  margin: 10px 0 20px;
}
.terminal-container .PrivateSwitchBase-root-282 {
  color: #fff;
}
.terminal-container .MuiFormControl-marginNormal {
  margin-top: 0;
}
input {
  outline: none;
}
.terminal-container .cardData.customCardWraper .MuiGrid-root.MuiGrid-item.text-right {
  padding-right: 13px;
}
.text-right {
  text-align: right;
}
.page-title {
  margin: 10px 15px;
}
.settings-main {
  padding: 10px 15px;
}
.settings-main ._loading_overlay_wrapper.css-79elbk {
  margin-top: 0;
}
.settings-main .MuiTabs-vertical {
  flex: 0 0 200px;
}


.min-wrap.pay-page ._loading_overlay_wrapper.css-79elbk {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.mb-3{margin-bottom:1rem;}
.pay-amount {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 10px 0;
  border-bottom: 1px solid #c4c4c4;
}
.font-sm{font-size: 0.75em;}
.terminal-title-grid.MuiGrid-container {
  font-size: 1.6rem;
  text-align: center;
}
.borderd{border:1px solid #ccc; padding:.5rem;}
.terminal-logo{max-width:100% !important;}

.MuiTypography-body1 input:focus {
  /* border: 1px solid #ccc; */
}

.MuiTypography-body1 input{
  padding: 16px 10px;
}


@media (min-width: 769px) {
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 > div:nth-child(2) > div,
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 > div:nth-child(2) > div > div,
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 {
    height: 100%;
  }
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 > div:nth-child(2) {
    height: calc(100% - 64px);
  }
}
@media (min-width: 768px) {
  .rightPanel{
    width: calc(100% - 220px);
    margin-left: 220px;
  }
  body.toggle .rightPanel{
    width: calc(100% - 50px);
    margin-left: 54px;
  }
  .sidebar{
    width: 220px;
  }
  .frequencyInput{
    margin-top:1.5rem !important;
  }
   .MuiInputBase-root {
    
    font-size: 0.7rem !important ;
  } 
  
}

@media only screen and (max-width: 768px) {
  .Dashboard_cardContentBoxTnx {
    overflow: auto !important;
  
}
  .frequencyInput{
    margin-top:1.5rem !important;
  }
  .MuiInputBase-root {
    
    font-size: 0.7rem !important ;
  } 
  
  .sidebar{
    width: 220px;
    height: 100%;
    
    position: absolute;
  }
  body.toggle .sidebar{
    background: #2F2F2F;
    min-height: 100vh;
    position: absolute;
    opacity: 1;
    padding-top: 4rem;
    z-index: 11;
    display: block;
  }
  body.toggle header.customHeader{
    z-index: 999;
  }
  .customHeader{
    position: relative;
    z-index: 11;
  }
  
  .MuiTypography-body1 input {
    width: 100%;
  }

  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
      width: 100%;
  }

  .MuiAppBar-colorDefault{
    margin-top: 20px;
  }

  .imgtermLogo {
    width: 25%;
  }

  .terminal-container-term{
    margin: 0 !important;
    padding: 0 !important;
  }

  .terminal-container-term div{
    margin: 0 !important;
    padding: 4px 8px 0px 6px !important;
  }

} 
@media (max-width: 480px) {
  .sidebar{
    width: 220px;
    opacity: 0;
    display: none;
    position: absolute;
  }
  .frequencyInput{
    margin-top:10% !important;
  }
  #root {
    padding-bottom: 58px;
  }


}
.achBakground
{
  /* background:   rgba(51, 93, 169, .1);
  color: rgba(51, 93, 169, 1); */
  margin-bottom: 20px;
}
.none{
   display: none;
 }
.block {
   display: block;
 }
.cardData.customCardWraper {
  /* cursor: pointer; */
  background: linear-gradient(90deg, rgb(76, 131, 144) 0%, rgba(35,140,161,1) 3%, rgb(52, 93, 169) 61%);
  color: #fff;
}
.maskData.customCardWraper {
  background: #acd6e6;
  color: #fff;
}
.cardData.customCardWraper .Card_header {
  border-bottom: 1px solid #056eb1;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
}
.cardData.customCardWraper .Card_header > h2 {
  font-weight: 500;
  font-size: 18px;
}
.cardBackButton{
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
}
.maskData.customCardWraper .Card_header > h2 {
  padding: 5px 10px;
  font-weight: 500;
  font-size: 20px;
}
.cardData.customCardWraper .MuiCardContent-root > label{
display: block;
color: #cbd2f9;
}
.cardData.customCardWraper .MuiCardContent-root > input {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 16px;
  display: block;
  font-weight: 600;
  letter-spacing: 10px;
  margin-bottom: 15px;
  width: 100%;
}
.cardData.customCardWraper .MuiGrid-root.MuiGrid-item > input{
  background: transparent;
  border: 0;
  color: #fff;
  letter-spacing: 8px;
  display: block;
  margin-top: 5px;
  width: 100%;
}
.cardData.customCardWraper .MuiTypography-gutterBottom,
.cardData.customCardWraper .MuiGrid-root.MuiGrid-item {
  color: #a9daf9;
  font-size: 14px;
}

input:focus{
  border: 0;
  box-shadow: none;
}

.cardData.customCardWraper .MuiGrid-root.MuiGrid-item > input::-webkit-input-placeholder, .cardData.customCardWraper .MuiCardContent-root > input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

.cardData.customCardWraper .MuiGrid-root.MuiGrid-item > input:-ms-input-placeholder, .cardData.customCardWraper .MuiCardContent-root > input:-ms-input-placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

.cardData.customCardWraper .MuiGrid-root.MuiGrid-item > input::placeholder,
.cardData.customCardWraper .MuiCardContent-root > input::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

/* Blur card css start ===================================== */
.blur.customCardWraper {
  
  color: #fff;
  background:linear-gradient(90deg, rgb(76, 131, 144) 0%, rgba(35,140,161,1) 3%, rgb(52, 93, 169) 61%);
}

.blur.customCardWraper .Card_header {
  border-bottom: 1px solid #c3c3c3;
  display: flex;
  padding: 5px 10px;
}
.blur.customCardWraper .Card_header > h2 {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}
.blur.customCardWraper .cardBackButton {
  text-transform: capitalize;
  font-weight: 500;
  color: #9a9a9a;
}
.blur.customCardWraper .MuiCardContent-root > label{
display: block;
color: #cbd2f9;
}
.blur.customCardWraper .MuiCardContent-root > input {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 16px;
  display: block;
  letter-spacing: 13px;
  margin-bottom: 15px;
}
.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input{
  background: transparent;
  border: 0;
  color: #fff;
  letter-spacing: 4px;
  display: block;
  width: 100%;

}
.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input::-webkit-input-placeholder{
  background: transparent;
  border: 0;
  color: #fff !important;
  letter-spacing: 4px;
  display: block;
  width: 100%;

}
.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input:-ms-input-placeholder{
  background: transparent;
  border: 0;
  color: #fff !important;
  letter-spacing: 4px;
  display: block;
  width: 100%;

}
.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input::placeholder{
  background: transparent;
  border: 0;
  color: #fff !important;
  letter-spacing: 4px;
  display: block;
  width: 100%;

}
.blur.customCardWraper .MuiTypography-gutterBottom,
.blur.customCardWraper .MuiGrid-root.MuiGrid-item {
  color: #fff;
  font-size: 14px;
}
input:focus{
  border: 0;
  box-shadow: none;
}

.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input::-webkit-input-placeholder, .blur.customCardWraper .MuiCardContent-root > input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1; 
}

.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input:-ms-input-placeholder, .blur.customCardWraper .MuiCardContent-root > input:-ms-input-placeholder {
  color: #fff;
  opacity: 1; 
}

.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input::placeholder,
.blur.customCardWraper .MuiCardContent-root > input::placeholder {
  color: #fff;
  opacity: 1; 
}



.btn.settledBtn {
  background: #0f7bc1;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
 
.btn.paidBtn {
  background: #03af38;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.ViewedBtn {
  background: #9b84bf;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.sentBtn {
  background: #5bc0de;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.addBtn {
  background: #0f7bc1;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor:pointer
}
.btn.addBtnml2 {
  margin-left:1rem; 
  background: #0f7bc1;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor:pointer
}
.btn.PenddingBtn {
  background: #f5aa02;
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.btn.RefundedBtn {
  background: #b38d54;
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.btn.Refunding {
  background: #808080	;
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.contentPart.transactionData table.MuiTable-root tr > td.MuiTableCell-root{
  padding: 5px 16px;
  line-height: normal;
}

.addInvoiceCustomePage .MuiCheckbox-root {
  color: rgb(249, 249, 249);
}
.addInvoiceCustomePage .PrivateSwitchBase-root-315 {
  padding: 0;
}
.addInvoiceCustomePage .MuiCheckbox-colorPrimary.Mui-checked {
  color: #fff;
}
.addInvoiceCustomePage .maskData.customCardWraper {
  background: rgb(136,205,219);
background: linear-gradient(90deg, rgba(136,205,219,1) 0%, rgba(35,140,161,1) 100%, rgba(9,189,227,1) 100%, rgba(215,216,217,1) 100%);
    color: #fff;
}
.CustomerPage .maskData.customCardWraper {
  background: rgb(136,205,219);
background: linear-gradient(90deg, rgba(136,205,219,1) 0%, rgba(35,140,161,1) 100%, rgba(9,189,227,1) 100%, rgba(215,216,217,1) 100%);
    color: #fff;
}
.addNewCardBtn.MuiButton-containedSecondary {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fdfdfd;
}

.addNewCardBtn.MuiButton-containedSecondary:hover {
  color: #464141;
  background-color: #ffffff;
}

.ViewTxnFormBox label{
  color: #333 !important;
  opacity: 1;
  font-weight: 500;
}

.pb-2{
  padding-bottom: 2rem;
}

.taxSelect  label{
  float: left;
  margin: 5px 10px 0 0;
}
.taxSelect select{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.textRight{
  text-align: right;
}


 @media only screen and (min-width: 1088px) {
  .frequencyInput{
    margin-top:1.5rem !important;
  }
  .MuiInputBase-root {
    
    font-size: 1rem !important ;
  } 
  
   
} 
@media only screen and (min-width: 1368px) {
  .frequencyInput{
    margin-top:2.8rem !important;
  }
  .MuiInputBase-root {
    
    font-size: 1rem !important ;
  } 
  
}

/* 3rdFeb'20 */
.vt-settings-container .tax-select-field > label,
.vt-settings-container .fee-name-field > label,
.vt-settings-container .discount-label-field > label {
  margin-right: 15px;
  margin-top: 0;
  white-space: nowrap;
}
.vt-settings-container + hr {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: none;
  border-top: 1px solid rgba(0,0,0,0.1);
}
.vt-settings-container .invoice-svc-fee-field .MuiFormControl-root {
  margin-top: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0;
}
.vt-settings-container .invoice-svc-fee-field .MuiFormControl-root > .MuiInputLabel-formControl {
  white-space: nowrap;
  margin-right: 0;
  position: static;
  position: initial;
}
.vt-settings-container .invoice-svc-fee-field .MuiFormControl-root > .MuiInputBase-root {
  margin: 0;
  width: 70px;
}
.vt-settings-container .tax-select-field {
  display: flex;
  align-items: center;
}
.settings-main .makeStyles-root-71 {
  min-height: calc(100vh - 230px);
}
.mt-4 {
  margin-top: 2rem;
}

.terminal-container.MuiContainer-root {
  padding: 0px 20px;
}

.terminal-container .terminal-inner {
  background: #fff;
    padding: 15px;
    margin-top: 18px;
    box-shadow: 4px 3px 7px #ccc;
}
@media (min-width: 1025px) { 
  .vt-settings-container .vt-settings-inner {
    max-width: 50%;
  }
}
@media (max-width: 1024px) {
  .settings-main .makeStyles-root-71 .makeStyles-tpTableFullW-77 {
    box-shadow: none;
  }
    
}
@media (max-width: 768px) {
  .vt-settings-container + hr {
    display: none;
  }
}
/* 21thFeb'20 */
.dashboard-cards-container .MuiPaper-elevation1 {
  height: 100%;
}
.achBakground h3 {
  margin-bottom: 10px;
}
.achBakground .MuiInputLabel-formControl[data-shrink="true"] {
  background: #ebeef6;
  padding: 0 8px;
  z-index: 1;
}
.mt-2 {
  margin-top: 1rem !important;
}
.vt-settings-container .tax-select-field > label.fee-name-label-settings {
  margin-top: 5px;
}


.bottomLogo { 
  text-align: center;
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 8px 0;
}

.sidebarMenu {
  max-height: calc(100% - 300px);
  overflow-y: auto;
}

.userName p {
  color: inherit;
}

.min-wrap {
  max-width: 950px;
  margin: 0 auto;
}
.min-wrap ._loading_overlay_wrapper.css-79elbk .terminal-inner {
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.bordered {
  border: 1px solid #ececec;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .banner-heading {
      font-size: 40px !important;
  }
}
@media (max-width:567px){
  .logo {
		width: 100%;
		text-align: center;
	}
}
.custom-select .MuiInputBase-input {
  height: 2.2rem;
}
.custom-select .MuiInputBase-input p{
  /* top:5px; */
  margin: 0;
  position: absolute;
  left: 2px;
}

/* Start 03-13-2020 */
.dashboard-table tr .MuiSvgIcon-root{
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
.MuiTableRow-root:hover .MuiSvgIcon-root{
  visibility: visible;
  opacity: 1;
}
/* .MuiInput-underline div.MuiInputBase-input{height: 40px;} */
/* .MuiInput-underline div.MuiInputBase-input p {
  line-height: 4;
} */
.invoice-table tr td:first-child{
  min-width: 170px;
}

.TypableSelectDetails-valueContainer-540 .css-1df318c-Input{
/* position: absolute; */
}
.terminal-select >div>div>div>div>div>div p +div{
  position: absolute;
  margin: 0;
}

.MuiGrid-item label.MuiInputLabel-formControl{
  z-index: 0;
}

.termContentsPage{
  width: 70%;
  margin-top: 25px;
}

.termContentsPage h1{
  color: #1e4661;
}

.termContentsPage h2{
  margin-bottom: 15px;
  color: 1e4661;
}

.termContentsPage p{
  line-height: 26px;
  text-align: justify;
}

._loading_overlay_wrapper.css-79elbk{
  padding: 38px 0 !important;
  margin-top: 30px  !important;
  box-shadow: 1px 1px 9px #d4d4d4 !important;
}

body{
  background: #f6f6f79c !important;
}


.email-invoice{
  background: #e27329 !important;
  color: #fff;
 }

 .emailInvoiceTerminal {
  margin-left: 10px !important;
}
.btnRed {
  background-color: #FF0000 !important;
  color: #fff !important;
}



.product-table tr td:first-child {
  min-width: 240px;
}

.addInvoiceCustomePage {
  padding: 10px;
}
 
/*Start Invoice table responsive */

@media (max-width: 768px) {
  /* Force table to not be like tables anymore */
	table.invoice-table, .invoice-table thead,.invoice-table tbody, .invoice-table th, .invoice-table td, .invoice-table tr { 
		display: block; 
	}
  
  table.invoice-table ,.invoice-table tr{    
    margin-bottom: 15px;
  }
	/* Hide table headers (but not display: none;, for accessibility) */
	.invoice-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.invoice-table tr { border: 1px solid #ccc; }
	
	.invoice-table td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
    padding-left: 40%; 
    width: 100% !important;
	}
	
	.invoice-table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 33%;
		left: 6px;
		width: 25%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
  }
  .invoice-table td .MuiFormControl-marginNormal{
    text-align: right
  }
  .invoice-table td .MuiFormControl-marginNormal input {
    text-align: right;
    padding-right: 5px;
  }
	.invoice-table td .MuiInputBase-root{font-size: 1rem !important}
	/*
	Label the data
	*/
	.invoice-table td:nth-of-type(1):before { content: "Item"; }
	.invoice-table td:nth-of-type(2):before { content: "Description"; }
	.invoice-table td:nth-of-type(3):before { content: "Unit Cost"; }
	.invoice-table td:nth-of-type(4):before { content: "Quantity"; }
	.invoice-table td:nth-of-type(5):before { content: "Line Unit"; }
	.invoice-table tr{
    text-align: center;
  }
  .invoice-table tr .MuiSvgIcon-root{
    margin: 15px 20px 15px 20px;
    visibility: visible;
    opacity: 1;
  }

  .termContentsPage {
    width: 90%;
  }

  
.emailInvoiceTerminal{
  width: 100%;
  margin-bottom: 12px !important;
}


.emailInvoiceTerminal {
  margin-left: 0px !important;
}

}

/*End Invoice table responsive */

/*End 03-13-2020 */

.dateFilter-table{
  float: right;
  padding: 0px 3px 0px 1px;
  margin-top: -12px;
}

.top-seciton  {
  display: inline-block;
  width: 100%;
}
.top-seciton > div{
  width: 50%;
  float: left;
  padding: 0 10px;
}
.top-seciton .MuiToolbar-root > div:nth-child(2){
  flex: 0 1 !important;
}
.top-seciton .dateFilter-table {
  float: right;
  margin-top: 2px;
}
.top-seciton .MuiFormControl-root { 
  width: 41%;
  margin: 0 10px;
}
.top-seciton .MuiToolbar-root > div:first-child {
  width: 168px;
}
.top-seciton .MuiToolbar-root > div:first-child h6 {
  line-height: 1.2;
  text-align: left;
}

/* .custom-liststyle .MuiFormControl-root {
  margin-top: 0.75rem !important;
  margin-bottom: 8px;
}
.custom-liststyle .MuiInputBase-input > div:first-child {
  margin-top: -10px;
}*/
.custom-liststyle p {
  text-align: left;
  /* top: -29px; */
}
.MTableToolbar-actions-422 {
  color: rgba(0, 0, 0, 0.54);
  width: 150px;
}

.top-seciton .MuiToolbar-root > div:nth-child(4){  
  width: 370px;
}
.MuiInputBase-input > div:first-child {
  /* margin-top: -8px; */
}

@media (max-width: 1110px) {
  .top-seciton .MuiFormControl-root {
    width: 95%;
}
}

@media (max-width: 1050px) {
  .top-seciton > div {
    width: 100%;
    float: left;
    padding: 0 10px;
}
.top-seciton .MuiFormControl-root {
  width: 45%;
}
}

@media (max-width: 600px) {
  .top-seciton .MuiFormControl-root {
    width: 100%;
    margin: 20px 0;   
}
.top-seciton > div {
  width: 100%;
  display: inline-block;
  text-align: center;
}
.top-seciton .MuiToolbar-root > div:first-child {
  width: 100%;
}.top-seciton .MuiToolbar-root > div:nth-child(4) {
  width: 100%;
}
.top-seciton .MuiToolbar-root > div:first-child h6 {
  text-align: center;
}
}




/* 27-05-2020 */
.top-logo-panel{  
  background: #fff;
  display: inline-block;
  padding: 5px 10px;
}
.error-quote-message{
  margin: 20px 0;
  font-size: 20px;
  color: #000;
}
.top-logo-panel img{    
  width: 70px;
  float: left;
}
.top-logo-panel h3{
  float: right;
  color: #311f9e;
  font-size: 20px;
  padding: 16px 20px;
}

.MuiTypography-body1 {
  /* text-align: justify; */
}
.payment-info {
  width: 70%;
}


.payment-auth-container{
  background: #311f9e;
  height: 100%;
}
.payment-auth-container > div.css-79elbk {
  background: transparent !important;
  padding: 38px 30px !important;
  box-shadow: none !important;
}
.terminal-container {
  /* height: 661px; */
}

.msg-container {
  padding: 30px 20px !important;
  width: 50%;
}
.msg-container p {
  color: #000;
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
}


.MuiGrid-spacing-xs-3 {
  margin: -11px!important;
}
#DemoForm label{
  display: block;
  margin-bottom:2px;
}
#DemoForm input{
  border:1px solid #e3e3e3!important;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  margin: 2px 0px;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 0 12px;
}
.btn.DeclinedBtn {
  background: #e45e5e;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.RequestedBtn {
  background : rgb(243, 243, 73);
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;

}
.navGrid
 {  
   display: flex;
 }
 .mr2{
   margin-right: 2px !important;
 }

 .ml5{
  margin-left: 5px !important;
}

.warning-display {
  background: rgb(255, 244, 229);
  margin-bottom: 20px !important;
}


.timer-wrapper {
  position: relative;
}
.timer-wrapper a.cancel-icon {
  position:absolute;
  top:0px;
  right:0px;
  color: #000;
}
.warning-display .MuiAlert-root {
  padding:0 16px;
}


@media only sreen and  (max-width: 768px) {
    body.toggle .footer-padd {
        padding-left: 50px;
    }
}

body .Login_login__PjBUD {
  width: 100%;
  height: 100%;
  position: absolute; }

.Login_paper__kOocm {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eaf0f1;
  padding: 20px;
  border-radius: 5px; }

.Login_avatar__3_Gdf {
  margin: 1px; }

.Login_form__2mkKy {
  width: 100%;
  margin-top: 1px; }

.Login_submit__3jVew {
  width: 100%; }

.Login_forget__2g-Y8 {
  width: 40%;
  float: left;
  margin: 5px 0 0 0 !important; }

.Login_back__2srPD {
  width: 20%;
  float: right;
  text-align: right;
  margin: 5px 0 0 0 !important; }

@-webkit-keyframes ErrorMessage_fade-in__1MZOI {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes ErrorMessage_fade-in__1MZOI {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.ErrorMessage_errors__2r1TB {
  background-color: #FC3F56;
  color: #FFFFFF;
  text-align: center;
  padding: 10px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.5);
  -webkit-animation: ErrorMessage_fade-in__1MZOI .5s;
          animation: ErrorMessage_fade-in__1MZOI .5s;
  font-family: Roboto;
  font-weight: bold;
  width: 20rem;
  display: flex;
  align-self: center;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 20px; }

body {
  font-family: 'Montserrat', 'sans-serif';
  font-size: 14px;
  color: #000;
  background: #f8f8f8;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

a {
  text-decoration: none; }

p {
  font-size: 14px;
  line-height: 22px;
  color: #4a4a4a;
  margin: 0;
  padding: 0; }

img {
  max-width: 100%; }

.Home_container__3myiG {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  z-index: 1; }

header.Home_headerPanel__2lckP {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px 0; }

.Home_topmenu__2tOzT > ul > li {
  float: left;
  margin-right: 30px; }

.Home_topmenu__2tOzT > ul > li > a {
  font-size: 20px;
  color: #000;
  text-transform: uppercase; }

.Home_topmenu__2tOzT > ul > li:hover > a {
  color: #179fd3; }

.Home_topmenu__2tOzT > ul > li:last-child {
  margin-right: 0px; }

.Home_bannerWrapper__w427O {
  width: 100%;
  background-size: cover;
  min-height: 600px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden; }

.Home_bannerHeading__3TMYm {
  font-size: 100px;
  font-family: 'Montserrat', 'sans-serif';
  margin: 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 10px; }

.Home_bannerHeading__3TMYm > span {
  display: block; }

.Home_infoWrapper__1_pHv {
  display: block;
  width: 100%;
  position: relative; }

.Home_infoContentBox__1LHj4 {
  display: flex;
  flex-wrap: wrap;
  border: 3px solid #2799d6;
  position: relative;
  top: -90px;
  background: #fff; }

.Home_infoContentBox__1LHj4 .Home_infoLeftPanel__YB5R2,
.Home_infoContentBox__1LHj4 .Home_infoRightPanel__20h3S {
  flex: 0 0 50%;
  padding: 15px;
  box-sizing: border-box; }

.Home_infoLeftPanel__YB5R2 h2,
.Home_infoLeftPanel__YB5R2 p {
  margin: 0; }

.Home_infoDetails__1Ugi2 h2 {
  margin: 0; }

.Home_infoDetails__1Ugi2 {
  margin: 15px 0; }

.Home_flexOptionWrapper__V14Pc {
  width: 100%;
  display: block; }

.Home_flexOptionWrapper__V14Pc > h1 {
  text-align: center;
  padding: 15px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 45px;
  color: #1796d5;
  font-weight: 800; }

.Home_choosePlanWrapper__2pz41 {
  display: flex;
  flex-wrap: wrap; }

.Home_choosePlanWrapper__2pz41 > .Home_block__1j4K9 {
  flex: 0 0 33.333%;
  max-width: calc(33.333% - 15px);
  margin-right: 15px;
  background: #fff;
  margin-bottom: 15px;
  border-bottom: 5px solid #0a6691;
  border-radius: 10px 10px 10px 10px; }

.Home_choosePlanWrapper__2pz41 > .Home_block__1j4K9:nth-child(3n) {
  margin-right: 0; }

.Home_headSection__1dUoO.Home_blueBg__O6mvg {
  background: #1796d5; }

.Home_headSection__1dUoO.Home_darkBlue__3JkCG {
  background: #0a6691; }

.Home_headSection__1dUoO.Home_skyBlue__1ZpzR {
  background: #33c0c8; }

.Home_headSection__1dUoO > h2 {
  padding: 15px;
  color: #fff;
  letter-spacing: 2px;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase; }

.Home_contentBox__1PSg7 {
  background: #fff;
  padding: 15px;
  text-align: center;
  height: auto;
  position: relative; }

.Home_paymentDetails__uFlMI > h2 {
  text-align: center;
  color: #1796d5; }

.Home_pricetxt__24Vug {
  font-size: 40px; }

.Home_dollortxt__vFapb,
.Home_decimal-txt__3yIXO {
  position: relative;
  top: -22px;
  font-size: 24px; }

.Home_permonthtxt__rEiHN {
  font-size: 22px;
  color: #1796d5;
  font-weight: 800;
  letter-spacing: 2px;
  text-align: center; }

.Home_paymentDetails__uFlMI {
  margin: 20px 0; }

.Home_listItem__37iUj {
  text-transform: capitalize;
  width: 80%;
  margin: 20px auto;
  border-top: 1px solid #1796d5;
  padding-top: 15px;
  margin-top: 25px; }

.Home_listItem__37iUj > li {
  text-align: left;
  line-height: 30px;
  font-size: 16px; }

.Home_contentBox__1PSg7::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  background: #1796d5;
  left: 0;
  bottom: 0; }

.Home_footerWrapper__1nHJL {
  background: #0a6691;
  padding: 20px;
  text-align: center; }

.Home_footerWrapper__1nHJL > a {
  margin: 0;
  color: #fff; }

@media (max-width: 991px) {
  .Home_choosePlanWrapper__2pz41 > .Home_block__1j4K9 {
    flex: 0 0 50%;
    max-width: calc(50% - 15px); }
  .Home_choosePlanWrapper__2pz41 > .Home_block__1j4K9:nth-child(3n) {
    margin-right: 15px; }
  .Home_choosePlanWrapper__2pz41 > .Home_block__1j4K9:nth-child(2n) {
    margin-right: 0; }
  .Home_container__3myiG {
    position: relative;
    z-index: 1; }
  .Home_bannerWrapper__w427O {
    position: absolute;
    z-index: 0;
    min-height: 220px; }
  .Home_infoContentBox__1LHj4 {
    top: 0; } }

@media (max-width: 567px) {
  .Home_infoContentBox__1LHj4 .Home_infoLeftPanel__YB5R2,
  .Home_infoContentBox__1LHj4 .Home_infoRightPanel__20h3S {
    flex: 0 0 100%; }
  .Home_topmenu__2tOzT {
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    padding-top: 15px; }
  .Home_topmenu__2tOzT > ul > li {
    float: none;
    display: inline-block; }
  .Home_choosePlanWrapper__2pz41 > .Home_block__1j4K9 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0 !important; } }

.Home_listItemInner__2kVgl {
  display: none; }

.Home_listItemInnerBlock__3lPaw {
  display: block; }

.Home_expendColor__3ATYU a {
  color: #1896d5;
  font-weight: bold; }

.Home_videobox__3QXX9 {
  position: absolute;
  top: 0;
  z-index: -1; }

.Home_videobox__3QXX9 video {
  width: 100%;
  position: fixed; }

.Home_flexOptionWrapper__V14Pc {
  margin-bottom: 35px; }

#Home_root__3_fX1 {
  padding-bottom: 0; }

#Home_root__3_fX1 > div {
  position: relative;
  min-height: 100%; }

.Home_footerWrapper__1nHJL {
  position: absolute;
  bottom: 0;
  width: 100%; }

@media (max-width: 768px) {
  .Home_bannerWrapper__w427O {
    position: relative; }
  .Home_videobox__3QXX9 video {
    position: static;
    position: initial; } }

@media only screen and (max-width:480px){
    .custom-styling-res {
        position: absolute;
        right: 0;
        top: 45px;
    }
    .contentPart {
        padding: 1rem;
        margin-top: 40px;
    }
    
}
@media only screen and (max-width: 767px)
{
.sidebar {
    width: 220px;
    height: 100%;
    position: absolute;
    z-index: 1;
    padding-top: 50px;
}
body.toggle .footer-padd {
    padding-left: 50px;
}
body.toggle .contentPart {
    margin-left: 54px;
}
body.toggle .sidebar .logoBox img {
    width: 50px;
    height: auto;
}
._loading_overlay_wrapper.css-79elbk {
    padding: 0 !important;
    background: none !important;
    box-shadow: none !important;
  }
  .contentPart main.MuiContainer-root.terminal-container {
    padding: 0px;
}
.tp-tabcustom div#vertical-tabpanel-0 .MuiBox-root {
    padding: 0 !important;
}
.custom-spacing {
    margin: 20px 0px 0px !Important;
}
.contentPart main.MuiContainer-root {
    padding: 0px;
}
.vt-settings-container .discount-label-field > label {
  
    font-size: 12px;
}
.custom-name-classes .MuiBox-root {
    padding: 10px 0;
}
.top-seciton {
    display: inline-block;
    width: 100%;
    padding: 20px 10px;
}
.MuiGrid-root.custom-class {
    margin: 20px 0px;
}

.MuiGrid-root.custom-class button {
    margin: 20px 0px 0px;
}
}
.Invoice_Appheader__laawG {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */ }

.Invoice_App__1MQV4 {
  text-align: center; }

/*------ request-page ---*/
.Invoice_requestformpage__1DJb9 .Invoice_adminBtn__gkFA_ {
  background: #4CAF50 !important; }

.Invoice_requestformpage__1DJb9 {
  background: #fafafa !important; }

.Invoice_requestformpage__1DJb9 .Invoice_MuiPaper-root__95Ts_ {
  margin-bottom: 60px !important; }

.Invoice_requestformpage__1DJb9 .Invoice_MuiPaper-root__95Ts_ {
  margin-bottom: 60px !important; }

.Invoice_requestformpage__1DJb9 .Invoice_saveforLetterBtn__rBPLw {
  background: #4CAF50 !important; }

.Invoice_requestformpage__1DJb9 .Invoice_saveforLetterBtn__rBPLw {
  background: #4CAF50 !important;
  color: #000 !important; }

.Invoice_requestformpage__1DJb9 .Invoice_sendPayment__22dOQ {
  background: #9c27b0 !important; }

.Invoice_top-header__TBGX6 {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 15px; }

.Invoice_logout-img__1uH02 {
  width: 18px;
  height: 18px;
  margin-left: 0px;
  margin-top: 6px;
  float: right; }

.Invoice_topheader__1fdPv {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 15px; }

.Invoice_logoutimg__1KgBw {
  width: 18px;
  height: 18px;
  margin-left: 0px;
  margin-top: 6px;
  float: right; }

.Invoice_MuiButton-containedSecondary__2N4ra {
  background-color: #4CAF50 !important; }

.Invoice_containerControl__mOXzs {
  max-width: 980px;
  width: 100%;
  margin-bottom: 50px; }

.Invoice_table__Hu4ic {
  background-color: #4CAF50; }

.Invoice_newClass__hPjix {
  background-color: #4CAF50; }

.Invoice_ml_2__3LYug {
  margin-left: 1rem !important; }

.Invoice_Underline__3UeH4 {
  color: blue;
  cursor: pointer; }

.Invoice_addNewProductBtn__19iTZ {
  background-color: #117CC1 !important;
  margin-left: 15px !important;
  margin-top: -4px !important;
  margin-bottom: 25px !important; }

.Invoice_addNewClient__11L1A {
  margin-top: 10px !important;
  background-color: #117CC1 !important; }

.Invoice_submit__2rWNO {
  background-color: #117CC1 !important; }

.Invoice_saveDraft__2ujvJ {
  margin-left: 1rem !important;
  background-color: #adb2b5 !important; }

.Invoice_emailInvoice__14twB {
  margin-right: 1rem !important;
  background-color: #e27329 !important; }

.Invoice_itemHead__3IRUV {
  min-width: 110px; }

.Invoice_pt6__3yY7D {
  padding-top: 2.5rem; }

.Invoice_unitCost3__1WjAQ {
  width: 5rem  !important; }

.Invoice_edtcelldiv___Saib .Invoice_MuiFormControl-marginNormal__25YCf {
  margin-top: -21px !important; }

@-webkit-keyframes SuccessMessage_fade-in__2rbjk {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes SuccessMessage_fade-in__2rbjk {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.SuccessMessage_successes__x-S3P {
  background-color: #28a745;
  color: #FFFFFF;
  text-align: center;
  padding: 10px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.5);
  -webkit-animation: SuccessMessage_fade-in__2rbjk .5s;
          animation: SuccessMessage_fade-in__2rbjk .5s;
  font-family: Roboto;
  font-weight: bold;
  width: 90%;
  display: flex;
  align-self: center;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 20px; }

.Products_nav_width_65__3gEV5 {
  width: 100%; }

.Products_ml_2__2Z_m- {
  margin-left: 1rem !important; }

.Products_successMsg__2oxcR {
  color: green; }

.Products_Underline__cCP5X {
  color: blue;
  cursor: pointer; }

.Products_formBox__3-CAU label {
  color: #333;
  opacity: 1; }

.Settings_nav_width_65__1a53V {
  width: 100%; }

.Settings_ml_2__3DHc7 {
  margin-left: 1rem !important; }

body .ForgotPassword_forgotPassword__3c0iO {
  background: url(/static/media/loginImage.17911ac9.jpg);
  width: 100%;
  height: 100%;
  position: absolute; }

.ForgotPassword_paper__3s3gu {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px; }

.ForgotPassword_avatar__3QGo1 {
  margin: 1px; }

.ForgotPassword_form__Di-Ru {
  width: 100%;
  margin-top: 1px; }

.ForgotPassword_text_right__3ndrO {
  text-align: right; }

.ForgotPassword_green__1c8wp {
  color: forestgreen; }

.ForgotPassword_red__LZD5R {
  color: red; }

.ForgotPassword_check-icon__3Bb3W {
  color: forestgreen;
  font-size: 12px;
  size: 10px; }

.ForgotPassword_pswfield__2pLxt {
  position: relative;
  margin-bottom: 25px;
  width: auto;
  height: auto; }

.ForgotPassword_instruction-section__1Hb45 {
  border-radius: 0 0 7px 7px;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 1;
  z-index: 1;
  padding: 6px 11px;
  background-color: white; }

.ForgotPassword_instructionsview__Ih_nw {
  display: block;
  width: 100%;
  float: left; }

@media (min-width: 600px) {
  .ForgotPassword_ErrorMessage_errors__20g1F__WP6j8 {
    width: 50%; }
  .ForgotPassword_max_Width__2WJ_u {
    max-width: 500px !important; } }

.Dashboard_custom_div__24GE4 .Dashboard_col__3XFDE {
  display: inline-block; }

.Dashboard_custom_div__24GE4 .Dashboard_right-align__115ON {
  text-align: right; }

.Dashboard_float_right__3JJ_E {
  float: right; }

.Dashboard_table_responsive__22-pD {
  overflow: auto; }

.Dashboard_custom_table__1aSWN table tbody tr td:nth-child(9) {
  min-width: 250px; }

.Dashboard_custom_table__1aSWN table tbody tr td:last-child {
  white-space: nowrap; }

.Dashboard_custom_table__1aSWN table tbody tr td:last-child i {
  vertical-align: bottom; }

.Dashboard_custom_table__1aSWN table tbody tr td:last-child a {
  text-decoration: none; }

.Dashboard_red__1SwIt.Dashboard_darken_2__vOWXa {
  background-color: #D32F2F !important; }

.Dashboard_custom_table__1aSWN .Dashboard_btn__3s_G5 {
  color: #fff;
  text-align: center;
  letter-spacing: .5px;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.Dashboard_float_left__1AuD1 {
  float: left; }

.Dashboard_download_div__3jE1t {
  padding-top: 30px !important; }

.Dashboard_download_icon__zH6Dz {
  vertical-align: middle; }

.Dashboard_download_div__3jE1t a {
  color: #4e48b3;
  font-size: 20px; }

.Dashboard_float_right__3JJ_E {
  float: right; }

.Dashboard_clearfix__3kpPE {
  clear: both; }

.Dashboard_nav_width_65__iSwje {
  width: 100%; }

.Dashboard_tpMainContentB__27lKL .Dashboard_input___2PC2w + field label {
  font-size: 22px;
  color: #000; }

.Dashboard_right_align__1OC4e {
  text-align: right; }

.Dashboard_tpDashboardM__37lp3 > .Dashboard_s2__29QwU {
  padding: 0; }

.Dashboard_tpDashboardM__37lp3 .Dashboard_download_div__3jE1t a {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.Dashboard_App_header__1YBkd {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */ }

.Dashboard_dashboard_panel1__30E6N {
  background: #f7f7f7;
  width: 100%; }

.Dashboard_dashboard_total__3uIFK {
  background: palegreen; }

.Dashboard_dashboard_panel1_2__1cCZK {
  background: gainsboro; }

.Dashboard_dashboard_panel1_1__3WlB3 {
  background: #52b152; }

.Dashboard_total_span__2Gy1V {
  -webkit-text-decoration: solid;
          text-decoration: solid; }

.Dashboard_card__13NHr {
  width: 100%; }

.Dashboard_customCard__JDcuW {
  width: 100%; }

.Dashboard_customCard__JDcuW .Dashboard_cardContent__3GJsV {
  padding: 0;
  width: 100%; }

.Dashboard_customCard__JDcuW .Dashboard_cardContent__3GJsV h4 {
  background: #0f7bc1;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  margin: 0; }

.Dashboard_customCard__JDcuW .Dashboard_cardContent__3GJsV .Dashboard_cardContentBox__3NctD {
  padding: 1rem; }

.Dashboard_cardContentBox__3NctD .Dashboard_totalGray__2XbB1, .Dashboard_totalYellow__1BQ5Y, .Dashboard_cardContentBox__3NctD .Dashboard_totalGreen__219Mc {
  padding: 1.3rem;
  text-align: center;
  font-weight: bold;
  margin: 1rem 0;
  color: #fff; }

.Dashboard_totalGray__2XbB1 {
  background: #888; }

.Dashboard_totalYellow__1BQ5Y {
  background: #dbde2585;
  font-weight: bold !important; }

.Dashboard_totalGreen__219Mc {
  background: #52b152; }

.Dashboard_Underline__26BWc {
  color: blue;
  cursor: pointer; }

.Dashboard_Dashboard_cardContentBoxTnx__JSHqX {
  overflow: scroll !important;
  height: 200px; }

.Dashboard_rightAlign__17B0l {
  float: right; }

@media screen and (max-width: 767px) {
  p.Dashboard_MuiTypography-root__3DpoP span {
    margin: 0 !important;
    float: none;
    display: block; }
  .Dashboard_userName__2QcKP .Dashboard_MuiPaper-root__3TM1b {
    width: 30%;
    right: 0;
    max-width: 200px;
    top: 45px; }
  .Dashboard_no-display__3vXnG {
    display: none; }
  .Dashboard_totalGray__2XbB1, .Dashboard_totalYellow__1BQ5Y, .Dashboard_totalGreen__219Mc {
    text-align: left !important; } }

@media screen and (max-width:767px){ 
  .no-display {
    display: none !Important;
  } 
}
body .SignUp_signup__35Yud {
  background: url(/static/media/loginImage.17911ac9.jpg);
  width: 100%;
  height: 100%;
  position: absolute; }

.SignUp_paper__JdFFk {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px; }

.SignUp_avatar__zjFZW {
  margin: 1px; }

.SignUp_form__3y4Fz {
  width: 100%;
  margin-top: 1px; }

.Company_pt_1__14hbm {
  padding-top: 1rem; }

.Company_ml_2__1IQl_ {
  margin-left: 1rem !important; }

.Company_hide__2uBKS {
  display: none;
  text-align: center; }

.Company_show__3Dj3K {
  display: block;
  text-align: center; }

.Company_pt_1__3PHQE {
  padding-top: 1rem; }

.Company_ml_2__30zN2 {
  margin-left: 1rem !important; }

.User_nav_width_65__3bO_d {
  width: 100%; }

body .User_userLink__2xHLg {
  width: 100%;
  height: 100%;
  position: absolute; }

.User_paper__sVEpc {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px; }

.User_avatar__1r8xB {
  margin: 1px; }

.User_form__1jLWb {
  width: 100%;
  margin-top: 1px; }

.User_error__123sq {
  font-size: 30px;
  color: red; }

.User_ml_2__1mscQ {
  margin-left: 1rem !important; }

.User_preview__197C9 {
  height: 4rem;
  width: 10rem; }

.User_hide__3JAOt {
  display: none;
  text-align: center; }

.User_show__29AqX {
  display: block;
  text-align: center; }

.User_contentPart__1kPoS {
  padding: 1rem; }

.User_withError__VvgJj {
  width: '100%';
  border: 2px;
  color: red;
  height: 100px; }

.User_removeError__zgXkS {
  width: '100%'; }

.User_rightImg__tR5n_ {
  padding: 0px 10px; }

.rightPanel ._loading_overlay_wrapper {
    padding: 10px !important;
}
.green{
    color: forestgreen
} 
.red{
    color: red;
}
.check-icon{
    color: forestgreen;
    font-size: 12px;
    size: 10px;
}
.pswfield{
    position: relative;
    margin-bottom: 25px;
    width: auto;
    height: auto;
}
.instruction-section{   
    border-radius: 0 0 7px 7px;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 1;
    z-index: 1;
    padding: 6px 11px;
    background-color: white;
    box-shadow: 3px 1px 11px 2px rgb(0 0 0 / 22%);
    -webkit-box-shadow: 3px 1px 11px 2px rgb(0 0 0 / 22%);
    -moz-box-shadow: 3px 1px 11px 2px rgb(0 0 0 / 22%);
}
.instructionsview {
    display: block;
    width: 100%;
    float: left;
}
.marginpsw{
    /* margin:20px ; */
}
@media only screen and (max-width:767px){
    .custom-main button {
        float: none;
        margin: 10px 2px !important;
        padding: 10px 14px;
    }
    
    .custom-main {
        width: 100%;
    max-width: 100% !important;
    flex: 100% 1 !important;
    padding: 10px !important;
    text-align: center;
    }
    .res-cus-center {
        text-align: center;
        margin: 20px 0px;
    }

}
.Terminal_nav_width_65__3wPBS {
  width: 100%; }

.Terminal_ml_2__ZxcUI {
  margin-left: 1rem !important; }

.Terminal_emailInvoice__3_GQV {
  margin-right: 1rem !important;
  background-color: #e27329 !important; }

.Terminal_mr_2__vzCB- {
  margin-right: 1rem; }

.Terminal_cursorPointer__3EWbL {
  cursor: pointer; }

.Terminal_buttonMargin__2mY9a {
  margin-right: 5px !important; }

.SecureUserCard_nav_width_65__1mYBQ {
  width: 100%; }

.SecureUserCard_ml_2__165zW {
  margin-left: 1rem !important; }

.Locations_nav_width_65__HnuvR {
  width: 100%; }

.Locations_ml_2__3-NO6 {
  margin-left: 1rem !important; }

.TermCondition_termLogo__2BQVy {
  margin-left: 36.3rem; }

.TermCondition_companyName__g1F4w {
  padding: 4px 10px;
  color: #0f7bc1;
  font-size: 24px;
  font-weight: 500; }

.TermCondition__loading_overlay_wrapper__3_Qjb .TermCondition_MuiGrid-justify-xs-center__fA2_j {
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px; }

.TermCondition_MuiGrid-justify-xs-center__fA2_j div {
  box-shadow: none; }

.TermCondition__loading_overlay_wrapper__3_Qjb .TermCondition_MuiGrid-item__PpBQ2 {
  text-align: center; }

.Transactions_nav_width_65__2Yejt {
  width: 100%; }

.Transactions_ml_2__1oI19 {
  margin-left: 1rem !important; }

.Transactions_successMsg__pzyuk {
  color: green; }

.Transactions_Underline__GyFLb {
  color: blue;
  cursor: pointer; }

.Transactions_formBox__2BwKC label {
  color: #333;
  opacity: 1; }

.Transactions_emailInvoice__38A87 {
  margin-left: 1rem !important;
  background: #e27229 !important; }

.NyTaxReports_Appheader__Frajg {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */ }

.NyTaxReports_App__dK9bg {
  text-align: center; }

/*------ request-page ---*/
.NyTaxReports_requestformpage__2y6v8 .NyTaxReports_adminBtn__12wq0 {
  background: #4CAF50 !important; }

.NyTaxReports_requestformpage__2y6v8 {
  background: #fafafa !important; }

.NyTaxReports_requestformpage__2y6v8 .NyTaxReports_MuiPaper-root__2VOvS {
  margin-bottom: 60px !important; }

.NyTaxReports_requestformpage__2y6v8 .NyTaxReports_MuiPaper-root__2VOvS {
  margin-bottom: 60px !important; }

.NyTaxReports_requestformpage__2y6v8 .NyTaxReports_saveforLetterBtn__3Oput {
  background: #4CAF50 !important; }

.NyTaxReports_requestformpage__2y6v8 .NyTaxReports_saveforLetterBtn__3Oput {
  background: #4CAF50 !important;
  color: #000 !important; }

.NyTaxReports_requestformpage__2y6v8 .NyTaxReports_sendPayment__cG02Y {
  background: #9c27b0 !important; }

.NyTaxReports_top-header__2lNHF {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 15px; }

.NyTaxReports_logout-img__IstyQ {
  width: 18px;
  height: 18px;
  margin-left: 0px;
  margin-top: 6px;
  float: right; }

.NyTaxReports_topheader__3-j1w {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 15px; }

.NyTaxReports_logoutimg__2WEat {
  width: 18px;
  height: 18px;
  margin-left: 0px;
  margin-top: 6px;
  float: right; }

.NyTaxReports_MuiButton-containedSecondary__rH63M {
  background-color: #4CAF50 !important; }

.NyTaxReports_containerControl__2OEKP {
  max-width: 980px;
  width: 100%;
  margin-bottom: 50px; }

.NyTaxReports_table__1PoVX {
  background-color: #4CAF50; }

.NyTaxReports_newClass__aiZw6 {
  background-color: #4CAF50; }

.NyTaxReports_ml_2__31E73 {
  margin-left: 1rem !important; }

.NyTaxReports_Underline__1oQYh {
  color: blue;
  cursor: pointer; }

.NyTaxReports_addNewClient__LkP2M {
  margin-top: 10px !important;
  background-color: #117CC1 !important; }

.NyTaxReports_submit__3oYys {
  background-color: #117CC1 !important; }

.NyTaxReports_saveDraft__3HvTV {
  margin-left: 1rem !important;
  background-color: #adb2b5 !important; }

.NyTaxReports_emailInvoice__1Rta6 {
  margin-right: 1rem !important;
  background-color: #e27329 !important; }

.NyTaxReports_itemHead__x4Dci {
  min-width: 110px; }

.NyTaxReports_pt6__3_kzb {
  padding-top: 2.5rem; }

.NyTaxReports_unitCost3__1IrS2 {
  width: 5rem  !important; }

.Tickets_nav_width_65__38dPa {
  width: 100%; }

.Tickets_ml_2__28bMA {
  margin-left: 1rem !important; }

.Tickets_successMsg__3bOPs {
  color: green; }

.Tickets_Underline__3xT5z {
  color: blue;
  cursor: pointer; }

.Tickets_formBox__2jVfu label {
  color: #333;
  opacity: 1; }

.Customers_nav_width_65__2swrL {
  width: 100%; }

.Customers_ml_2__1Ku7J {
  margin-left: 1rem !important; }

.Customers_successMsg__P5TH6 {
  color: green; }

.Customers_Underline__2kfGE {
  color: blue;
  cursor: pointer; }

.Customers_formBox__1IrV1 label {
  color: #333;
  opacity: 1; }

.Recurrence_nav_width_65__1Jo4f {
  width: 100%; }

.Recurrence_ml_2__26_jE {
  margin-left: 1rem !important; }

.Recurrence_successMsg__l21Jv {
  color: green; }

.Recurrence_Underline__3bjuP {
  color: blue;
  cursor: pointer; }

.Recurrence_formBox__356D1 label {
  color: #333;
  opacity: 1; }

.Recurrence_btnRed__2zQIs {
  background: #ef0a0a !important;
  color: #fff !important;
  margin-left: 1rem !important; }

.Payment_App_header__3zphD {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */ }

.Payment_payBtn__3ZZLn {
  color: white !important;
  background: green !important;
  border-radius: 50px !important;
  margin-bottom: 12px !important;
  width: 100% !important; }

.Payment_invoiceDetail__d6RUd {
  margin-top: 2.5rem; }

.Payment_cardNum__1jmmY {
  width: 34rem;
  height: 3rem;
  letter-spacing: 1.65rem; }

.Payment_expDate__2PZum {
  width: 10rem;
  height: 3.3rem;
  padding: 0px 3rem; }

.Payment_cardCvv__1grfl {
  width: 5rem;
  height: 3.3rem;
  padding: 0px 1rem; }

.Payment_viewInvoice__3A59I {
  border-radius: 50px !important;
  width: 100%; }

@media only screen and (max-width: 480px) {
  .Payment_cardNum__1jmmY {
    letter-spacing: 0.4rem; } }

.errorPageGeneralStyle { text-align: justify; }

@media only screen and (max-width: 900px) {
  .errorPageStyle {
    max-width: 90%;
  }
}

@media only screen and (min-width: 901px) {
  .errorPageStyle {
    max-width: 50%;
    text-align: center;
  }
}
.Payment_App_header__eRaoD {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */ }

.Payment_payBtn__1eT2e {
  color: white !important;
  background: green !important;
  border-radius: 50px !important;
  margin-bottom: 12px !important;
  width: 100% !important; }

.Payment_invoiceDetail__3VY6f {
  margin-top: 2.5rem; }

.Payment_cardNum__2DsHr {
  width: 34rem;
  height: 3rem;
  letter-spacing: 1.65rem; }

.Payment_expDate__2Fw2P {
  width: 10rem;
  height: 3.3rem;
  padding: 0px 3rem; }

.Payment_cardCvv__2KekA {
  width: 5rem;
  height: 3.3rem;
  padding: 0px 1rem; }

.Payment_viewInvoice___JjR7 {
  border-radius: 50px !important;
  width: 100%; }

@media only screen and (max-width: 480px) {
  .Payment_cardNum__2DsHr {
    letter-spacing: 0.4rem; } }

.Pages_nav_width_65__ZsuEi {
  width: 100%; }

.Pages_ml_2__2hvJ6 {
  margin-left: 1rem !important; }

.Pages_successMsg__1fM4H {
  color: green; }

.Pages_Underline__MAwgM {
  color: blue;
  cursor: pointer; }

.Pages_formBox__18RqB label {
  color: #333;
  opacity: 1; }

.RequestForm_Appheader__BnWAQ {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 300px;
  font-size: calc(10px + 2vmin);
  /* color: black; */ }

.RequestForm_App__2uJGC {
  text-align: center; }

/*------ request-page ---*/
.RequestForm_requestformpage__104gE .RequestForm_adminBtn__1HCA3 {
  background: #4CAF50 !important; }

.RequestForm_requestformpage__104gE .RequestForm_refundBtn__3eVsG {
  background: #556cd6 !important; }

.RequestForm_requestformpage__104gE {
  background: #aee3fd !important; }

.RequestForm_requestformpage__104gE .RequestForm_MuiPaper-root__lblpf {
  margin-bottom: 60px !important; }

.RequestForm_requestformpage__104gE .RequestForm_MuiPaper-root__lblpf {
  margin-bottom: 60px !important; }

.RequestForm_requestformpage__104gE .RequestForm_saveforLetterBtn__2nBan {
  background: #4CAF50 !important; }

.RequestForm_requestformpage__104gE .RequestForm_saveforLetterBtn__2nBan {
  background: #4CAF50 !important;
  color: #000 !important; }

.RequestForm_requestformpage__104gE .RequestForm_sendPayment__yMpvn {
  background: #9c27b0 !important; }

.RequestForm_top-header__3eF8z {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 15px; }

.RequestForm_logout-img__31P_U {
  width: 18px;
  height: 18px;
  margin-left: 0px;
  margin-top: 6px;
  float: right; }

.RequestForm_topheader__MgfT8 {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 15px; }

.RequestForm_logoutimg__3v90l {
  width: 18px;
  height: 18px;
  margin-left: 0px;
  margin-top: 6px;
  float: right; }

.RequestForm_MuiButton-containedSecondary__24mdn {
  background-color: #4CAF50 !important; }

.RequestForm_containerControl__3_MDY {
  max-width: 1140px;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  margin: 0px; }

.RequestForm_intakePageHeading__1xeL_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 10px 0px; }

.RequestForm_requestformpageLoading__1a-iA {
  padding: 0px !important;
  margin-top: 0px !important;
  width: 87% !important; }

body .ServiceInfo_login__jSS4o {
  background: url(/static/media/loginImage.17911ac9.jpg);
  width: 100%;
  height: 100%;
  position: absolute; }

.ServiceInfo_paper__2sR7n {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px; }

.ServiceInfo_avatar__2RFmB {
  margin: 1px; }

.ServiceInfo_form__2lqgc {
  width: 100%;
  margin-top: 1px; }

@media (max-width: 1024px) {
  .ServiceInfo_service_container__1IvXH > div > div {
    width: 100%;
    display: inline-block !important; }
  .ServiceInfo_service_container__1IvXH > div > div > div {
    max-width: 100%;
    margin-left: 30px; } }

.geosuggest {
    font-size: 1rem;
    position: relative;
    width: 80%;
    margin: 1em auto;
    text-align: left;
}
.geosuggest__input {
    width: 100%;
    border: 2px solid transparent;
    box-shadow: 0 0 1px #3d464d;
    padding: .5em 1em;
    transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
    border-color: #267dc0;
    box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #267dc0;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
    font-size: 1rem;
    padding: .5em .65em;
    cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
    background: #f5f5f5;
}
.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
    background: #ccc;
}
.geosuggest__item__matched-text {
    font-weight: bold;
}
 
.AutoSuggestInput_formControlWrapper__1VLnw input[type=text] {
  display: block;
  padding: 6px 10px;
  width: 100%; }

.AutoSuggestInput_saveforLetterBtn__1Pa9g:not([disabled]) {
  background: #4CAF50 !important; }

.InvoiceEdit_App_header__2clyS {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */ }

.InvoiceEdit_body_section__1XbQ7 {
  min-height: calc(100vh - 44px);
  margin-top: 0px;
  background: #fafafa;
  margin-top: 44px; }

.InvoiceEdit_left_nav__15irQ {
  width: 260px;
  height: 200px;
  float: left; }

.InvoiceEdit_left_nav__15irQ ul {
  margin: 0;
  padding: 0; }

.InvoiceEdit_left_nav__15irQ ul li {
  list-style: none;
  padding: 20px; }

.InvoiceEdit_left_nav__15irQ ul li a {
  color: #000;
  font-size: 16px;
  padding: 5px; }

.InvoiceEdit_left_nav__15irQ ul li a i {
  vertical-align: top;
  font-size: 28px;
  margin-right: 10pxl; }

.InvoiceEdit_body_content_section__2Jy4p {
  /* padding: 20px; */
  float: left;
  width: calc(100% - 270px); }

.InvoiceEdit_body_content_section__2Jy4p .InvoiceEdit_wht_box__399gk {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.21);
  border-radius: 10px; }

.InvoiceEdit_mySnackbarRed__MovUO {
  background: #E9454C !important;
  font-weight: bold !important;
  margin: 0 1rem 1rem !important; }

.InvoiceEdit_mySnackbarBlue__n9BAU {
  background: #157FFB !important;
  font-weight: bold !important;
  margin: 0 1rem 1rem !important; }

h2.InvoiceEdit_form_heading__2Nsyr {
  font-size: 20px;
  padding: 5px 5px 5px; }

.InvoiceEdit_invoice_wth__DxSjQ .InvoiceEdit_MuiGrid-root__12pWS.InvoiceEdit_MuiGrid-item__2ehqp {
  max-width: 100%;
  flex-basis: 100%;
  padding-right: 1rem; }

.InvoiceEdit_MuiPaper-root__1zqgq h2 {
  text-align: left;
  padding-left: 1rem; }

.InvoiceEdit_mt_16__2CURW {
  margin-top: 16px !important; }

.InvoiceEdit_mt_30__1Adoo {
  margin-top: 30px !important; }

.InvoiceEdit_mb_8__1SpYU {
  margin-bottom: 8px !important; }

.InvoiceEdit_ml_1__1iph7 {
  margin-left: 1rem !important; }

.InvoiceEdit_pl_1__k0Ht4 {
  padding-left: 1rem !important; }

.InvoiceEdit_pb_1__2eWrd {
  padding-bottom: 1rem !important; }

.InvoiceEdit_pt_1__tvVpH {
  padding-top: 1rem; }

.InvoiceEdit_w_100__1_boW {
  width: 100% !important; }

.InvoiceEdit_text_left__MZoYX {
  text-align: left; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  /* background: #dedede; */
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.MuiButton-root{
  text-transform: none !important;
}
.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.error-pay {
  background-color:#fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.tpMainContentB .wht_box {
    background: #fff;
    padding: 30px;      
}
.nav_width_65 .tpMainContentB{
	width: calc(100% - 175px); 
  margin-left: 175px;  
}
.tpMainContentB {  
	margin-left: 40px;
	width: calc(100% - 40px);    
	transition: all .3s;
  margin-top: 40px;
  padding: 30px; 
}
.MuiTablePagination-root:last-child {
  text-align: center;
}
.tpInvoiceEditM .MuiFormControl-root {
    display: flex;
    padding-right: 10px;
}
.tpInvoiceEditM .tpInvoiceHistory {
    margin-top: 16px;
    padding-left: 1rem;
}
.tpInvoiceEditM .tpInvoiceHistory .MuiInput-root {
    margin-right: 0 !important;
}
.ml_2 .MuiInputLabel-formControl {
  margin-left: 1rem;
}
.tpInvoiceEditM {
  margin-top: 0;
}
@media (max-width: 768px) {
  .MuiTablePagination-root .MuiTablePagination-toolbar {
    height: auto;
    display: block;
    padding: 0;
  }
	.tpMainContentB{  
		margin-left: 40px !important;
		width: calc(100% - 40px) !important;   
	}	
}
.dashboard-table thead tr .MuiTableCell-alignRight{
  text-align: left !important;
  color: #fff;
}

.dashboard-table{
  margin-top: 25px;
}

.dashboard-table thead tr {
  background: #666;
}

.dashboard-table thead tr .MuiTableCell-alignRight:nth-child(2){
  width: 60%;
}

.dashboard-table tbody tr .MuiTableCell-alignRight:nth-child(2){
  width: 60%;
  text-align: left;
}

.dashboard-table tbody tr .MuiTableCell-alignRight:nth-child(2) .MuiFormControl-marginNormal{
  width: 100%;
}

.dashboard-table thead tr .MuiTableCell-alignRight:last-child{
  white-space: nowrap;
}

.dashboard-table tbody tr .MuiTableCell-alignRight:last-child{
  white-space: nowrap;
}

.sub-field{
  position: absolute !important;
  top: -22px;
  width: 63% !important;
  right: 10px;
}

.sub-field1{
  position: absolute !important;
  top: -22px;
  left: -13px;
}

.sub-total .MuiGrid-root{
  position: relative;
}

.sub-total{
  margin-top: 25px;
}

.sub-total .MuiGrid-root.MuiGrid-grid-xs-5{
  text-align: right;
}

.sub-total .MuiGrid-root{
  margin: 0;
  max-width: none;
  max-width: initial;
}

.sub-total .MuiGrid-root.MuiGrid-grid-xs-7:last-child{
  font-weight: 600;
}

.sub-total .MuiGrid-root.MuiGrid-grid-xs-5:last-child{
  font-weight: 600;
}

._loading_overlay_wrapper.css-79elbk{
  background: #fff;
  padding: 15px;
  margin-top: 18px;
  box-shadow: 4px 3px 7px #ccc;
}

.MuiContainer-root {
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
}

.MuiGrid-item .makeStyles-root-71 .MuiBottomNavigationAction-root{
  padding: 6px 8px 8px;
}
@media (max-width:767px){
  [class*='tableWrapper'] .dashboard-table thead tr .MuiTableCell-alignRight{
    min-width:100px;
  }
}
@media (max-width:599px){
  .roleName {
    font-size: 14px;
  }
  .userName .MuiButton-label p {
      font-size: 12px;
  }
}
@media screen and (max-width:767px){
    .MuiTablePagination-root {
        width: 100% !important;
        max-width: 250px;
        min-width: 250px;
        overflow: auto !important;
    }
}
