.nav_width_65{
    width: 100%;
}
.ml_2
{
    margin-left: 1rem !important;
}
.successMsg{
    color: green;
}
.Underline{
    color :blue;
    cursor:pointer
}

.formBox label{
    color: #333;
    opacity: 1;
}
.emailInvoice{
    margin-left: 1rem !important;
    background: #e27229 !important
}
