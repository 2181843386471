@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
    font-family:'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f1f1;
}

[class*='Mui']{
  font-family: inherit !important;
}
[class*='Mui'] .material-icons{
  font-family: 'Material Icons' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace;
}

#root {
  position: relative;
  min-height: 100%;
  padding-bottom: 37px;
}
.wrapper {
  display: flex;
}
.rightPanel{
  width: 100%;
}


.sidebar{
  width: 16rem;
  background: #2F2F2F;
  height: 100vh;
  position: fixed;
}

body.toggle .sidebar{
  width: 54px;
}

body.toggle .sidebar .logoBox img{
  width: 50px;
  height: 50px;
}

.left-control{  
  width: calc(100% - 40px);
}



.sidebar,
body.toggle .sidebar .logoBox img{
  transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}


.logoBox{
  text-align: center;
  padding: 15px 0;
}

.customHeader header{
  background: #117CC1;
}

.username > span{
  color: #fff;
}
.contentPart{
  padding: 1rem;
}
.MuiTypography-gutterBottom.errorCard{
  color: red !important;
}
labels.errorCard{
  color: red !important;
}

.noteMsg{
 
  color: #fff;
    background: #0f7bc1;
    padding: 7px;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
}
.errorMsg{
 
  color: #fff;
    background:#e45e5e;
    padding: 7px;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
}
.successMsg{
 
  color: #fff;
    background:#4dbf4d;
    padding: 7px;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
}
.noteMsg span, .errorMsg span, .successMsg span{ font-weight: 600;}




.Redcursor{
  cursor: no-drop;
}
.ml2{
  margin-left:2rem; 
}
.tabCustom{
  margin-top: -1.5rem,
}
.profile-logo  {
  width: 35px;
  flex-basis: 35px;
  height:35px;
  border-radius: 50%;
}
.customHeader .MuiToolbar-regular {
  min-height: 44px;
  height: 44px;
}
.tp-tabcustom > .MuiTypography-root > .MuiBox-root  {
  padding: 24px 15px;
}

header.customHeader{
  position: relative;
  z-index: 1;
}
.react-form-builder .react-form-builder-toolbar{
  margin-top:0px !important
} 
.Dashboard_cardContentBoxTnx {
  overflow: auto !important;
  max-height: 400px;
}

.terminal-container-term ._loading_overlay_wrapper .MuiGrid-item{
  text-align: center;
  max-width: 100%;
}

.imgtermLogo {
  margin-left: 0 !important;
  width: 10%;
  margin: 0 auto;
  text-align: center;
}
.MuiInputBase-root {
  z-index: 0;
}


@media (max-width: 1440px) {
  .Dashboard_cardContentBoxTnx {
    max-height: 300px;
  }
}
.terminal-container .terminal-title {
  margin: 10px 0 20px;
}
.terminal-container .PrivateSwitchBase-root-282 {
  color: #fff;
}
.terminal-container .MuiFormControl-marginNormal {
  margin-top: 0;
}
input {
  outline: none;
}
.terminal-container .cardData.customCardWraper .MuiGrid-root.MuiGrid-item.text-right {
  padding-right: 13px;
}
.text-right {
  text-align: right;
}
.page-title {
  margin: 10px 15px;
}
.settings-main {
  padding: 10px 15px;
}
.settings-main ._loading_overlay_wrapper.css-79elbk {
  margin-top: 0;
}
.settings-main .MuiTabs-vertical {
  flex: 0 0 200px;
}


.min-wrap.pay-page ._loading_overlay_wrapper.css-79elbk {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.mb-3{margin-bottom:1rem;}
.pay-amount {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 10px 0;
  border-bottom: 1px solid #c4c4c4;
}
.font-sm{font-size: 0.75em;}
.terminal-title-grid.MuiGrid-container {
  font-size: 1.6rem;
  text-align: center;
}
.borderd{border:1px solid #ccc; padding:.5rem;}
.terminal-logo{max-width:100% !important;}

.MuiTypography-body1 input:focus {
  /* border: 1px solid #ccc; */
}

.MuiTypography-body1 input{
  padding: 16px 10px;
}


@media (min-width: 769px) {
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 > div:nth-child(2) > div,
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 > div:nth-child(2) > div > div,
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 {
    height: 100%;
  }
  .Dashboard_cardContentBoxTnx .MuiPaper-elevation2 > div:nth-child(2) {
    height: calc(100% - 64px);
  }
}
@media (min-width: 768px) {
  .rightPanel{
    width: calc(100% - 220px);
    margin-left: 220px;
  }
  body.toggle .rightPanel{
    width: calc(100% - 50px);
    margin-left: 54px;
  }
  .sidebar{
    width: 220px;
  }
  .frequencyInput{
    margin-top:1.5rem !important;
  }
   .MuiInputBase-root {
    
    font-size: 0.7rem !important ;
  } 
  
}

@media only screen and (max-width: 768px) {
  .Dashboard_cardContentBoxTnx {
    overflow: auto !important;
  
}
  .frequencyInput{
    margin-top:1.5rem !important;
  }
  .MuiInputBase-root {
    
    font-size: 0.7rem !important ;
  } 
  
  .sidebar{
    width: 220px;
    height: 100%;
    
    position: absolute;
  }
  body.toggle .sidebar{
    background: #2F2F2F;
    min-height: 100vh;
    position: absolute;
    opacity: 1;
    padding-top: 4rem;
    z-index: 11;
    display: block;
  }
  body.toggle header.customHeader{
    z-index: 999;
  }
  .customHeader{
    position: relative;
    z-index: 11;
  }
  
  .MuiTypography-body1 input {
    width: 100%;
  }

  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
      width: 100%;
  }

  .MuiAppBar-colorDefault{
    margin-top: 20px;
  }

  .imgtermLogo {
    width: 25%;
  }

  .terminal-container-term{
    margin: 0 !important;
    padding: 0 !important;
  }

  .terminal-container-term div{
    margin: 0 !important;
    padding: 4px 8px 0px 6px !important;
  }

} 
@media (max-width: 480px) {
  .sidebar{
    width: 220px;
    opacity: 0;
    display: none;
    position: absolute;
  }
  .frequencyInput{
    margin-top:10% !important;
  }
  #root {
    padding-bottom: 58px;
  }


}
.achBakground
{
  /* background:   rgba(51, 93, 169, .1);
  color: rgba(51, 93, 169, 1); */
  margin-bottom: 20px;
}
.none{
   display: none;
 }
.block {
   display: block;
 }
.cardData.customCardWraper {
  /* cursor: pointer; */
  background: linear-gradient(90deg, rgb(76, 131, 144) 0%, rgba(35,140,161,1) 3%, rgb(52, 93, 169) 61%);
  color: #fff;
}
.maskData.customCardWraper {
  background: #acd6e6;
  color: #fff;
}
.cardData.customCardWraper .Card_header {
  border-bottom: 1px solid #056eb1;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
}
.cardData.customCardWraper .Card_header > h2 {
  font-weight: 500;
  font-size: 18px;
}
.cardBackButton{
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
}
.maskData.customCardWraper .Card_header > h2 {
  padding: 5px 10px;
  font-weight: 500;
  font-size: 20px;
}
.cardData.customCardWraper .MuiCardContent-root > label{
display: block;
color: #cbd2f9;
}
.cardData.customCardWraper .MuiCardContent-root > input {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 16px;
  display: block;
  font-weight: 600;
  letter-spacing: 10px;
  margin-bottom: 15px;
  width: 100%;
}
.cardData.customCardWraper .MuiGrid-root.MuiGrid-item > input{
  background: transparent;
  border: 0;
  color: #fff;
  letter-spacing: 8px;
  display: block;
  margin-top: 5px;
  width: 100%;
}
.cardData.customCardWraper .MuiTypography-gutterBottom,
.cardData.customCardWraper .MuiGrid-root.MuiGrid-item {
  color: #a9daf9;
  font-size: 14px;
}

input:focus{
  border: 0;
  box-shadow: none;
}

.cardData.customCardWraper .MuiGrid-root.MuiGrid-item > input::placeholder,
.cardData.customCardWraper .MuiCardContent-root > input::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

/* Blur card css start ===================================== */
.blur.customCardWraper {
  
  color: #fff;
  background:linear-gradient(90deg, rgb(76, 131, 144) 0%, rgba(35,140,161,1) 3%, rgb(52, 93, 169) 61%);
}

.blur.customCardWraper .Card_header {
  border-bottom: 1px solid #c3c3c3;
  display: flex;
  padding: 5px 10px;
}
.blur.customCardWraper .Card_header > h2 {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}
.blur.customCardWraper .cardBackButton {
  text-transform: capitalize;
  font-weight: 500;
  color: #9a9a9a;
}
.blur.customCardWraper .MuiCardContent-root > label{
display: block;
color: #cbd2f9;
}
.blur.customCardWraper .MuiCardContent-root > input {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 16px;
  display: block;
  letter-spacing: 13px;
  margin-bottom: 15px;
}
.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input{
  background: transparent;
  border: 0;
  color: #fff;
  letter-spacing: 4px;
  display: block;
  width: 100%;

}
.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input::placeholder{
  background: transparent;
  border: 0;
  color: #fff !important;
  letter-spacing: 4px;
  display: block;
  width: 100%;

}
.blur.customCardWraper .MuiTypography-gutterBottom,
.blur.customCardWraper .MuiGrid-root.MuiGrid-item {
  color: #fff;
  font-size: 14px;
}
input:focus{
  border: 0;
  box-shadow: none;
}

.blur.customCardWraper .MuiGrid-root.MuiGrid-item > input::placeholder,
.blur.customCardWraper .MuiCardContent-root > input::placeholder {
  color: #fff;
  opacity: 1; 
}



.btn.settledBtn {
  background: #0f7bc1;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
 
.btn.paidBtn {
  background: #03af38;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.ViewedBtn {
  background: #9b84bf;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.sentBtn {
  background: #5bc0de;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.addBtn {
  background: #0f7bc1;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor:pointer
}
.btn.addBtnml2 {
  margin-left:1rem; 
  background: #0f7bc1;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor:pointer
}
.btn.PenddingBtn {
  background: #f5aa02;
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.btn.RefundedBtn {
  background: #b38d54;
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.btn.Refunding {
  background: #808080	;
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.contentPart.transactionData table.MuiTable-root tr > td.MuiTableCell-root{
  padding: 5px 16px;
  line-height: normal;
}

.addInvoiceCustomePage .MuiCheckbox-root {
  color: rgb(249, 249, 249);
}
.addInvoiceCustomePage .PrivateSwitchBase-root-315 {
  padding: 0;
}
.addInvoiceCustomePage .MuiCheckbox-colorPrimary.Mui-checked {
  color: #fff;
}
.addInvoiceCustomePage .maskData.customCardWraper {
  background: rgb(136,205,219);
background: linear-gradient(90deg, rgba(136,205,219,1) 0%, rgba(35,140,161,1) 100%, rgba(9,189,227,1) 100%, rgba(215,216,217,1) 100%);
    color: #fff;
}
.CustomerPage .maskData.customCardWraper {
  background: rgb(136,205,219);
background: linear-gradient(90deg, rgba(136,205,219,1) 0%, rgba(35,140,161,1) 100%, rgba(9,189,227,1) 100%, rgba(215,216,217,1) 100%);
    color: #fff;
}
.addNewCardBtn.MuiButton-containedSecondary {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fdfdfd;
}

.addNewCardBtn.MuiButton-containedSecondary:hover {
  color: #464141;
  background-color: #ffffff;
}

.ViewTxnFormBox label{
  color: #333 !important;
  opacity: 1;
  font-weight: 500;
}

.pb-2{
  padding-bottom: 2rem;
}

.taxSelect  label{
  float: left;
  margin: 5px 10px 0 0;
}
.taxSelect select{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.textRight{
  text-align: right;
}


 @media only screen and (min-width: 1088px) {
  .frequencyInput{
    margin-top:1.5rem !important;
  }
  .MuiInputBase-root {
    
    font-size: 1rem !important ;
  } 
  
   
} 
@media only screen and (min-width: 1368px) {
  .frequencyInput{
    margin-top:2.8rem !important;
  }
  .MuiInputBase-root {
    
    font-size: 1rem !important ;
  } 
  
}

/* 3rdFeb'20 */
.vt-settings-container .tax-select-field > label,
.vt-settings-container .fee-name-field > label,
.vt-settings-container .discount-label-field > label {
  margin-right: 15px;
  margin-top: 0;
  white-space: nowrap;
}
.vt-settings-container + hr {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: none;
  border-top: 1px solid rgba(0,0,0,0.1);
}
.vt-settings-container .invoice-svc-fee-field .MuiFormControl-root {
  margin-top: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0;
}
.vt-settings-container .invoice-svc-fee-field .MuiFormControl-root > .MuiInputLabel-formControl {
  white-space: nowrap;
  margin-right: 0;
  position: initial;
}
.vt-settings-container .invoice-svc-fee-field .MuiFormControl-root > .MuiInputBase-root {
  margin: 0;
  width: 70px;
}
.vt-settings-container .tax-select-field {
  display: flex;
  align-items: center;
}
.settings-main .makeStyles-root-71 {
  min-height: calc(100vh - 230px);
}
.mt-4 {
  margin-top: 2rem;
}

.terminal-container.MuiContainer-root {
  padding: 0px 20px;
}

.terminal-container .terminal-inner {
  background: #fff;
    padding: 15px;
    margin-top: 18px;
    box-shadow: 4px 3px 7px #ccc;
}
@media (min-width: 1025px) { 
  .vt-settings-container .vt-settings-inner {
    max-width: 50%;
  }
}
@media (max-width: 1024px) {
  .settings-main .makeStyles-root-71 .makeStyles-tpTableFullW-77 {
    box-shadow: none;
  }
    
}
@media (max-width: 768px) {
  .vt-settings-container + hr {
    display: none;
  }
}
/* 21thFeb'20 */
.dashboard-cards-container .MuiPaper-elevation1 {
  height: 100%;
}
.achBakground h3 {
  margin-bottom: 10px;
}
.achBakground .MuiInputLabel-formControl[data-shrink="true"] {
  background: #ebeef6;
  padding: 0 8px;
  z-index: 1;
}
.mt-2 {
  margin-top: 1rem !important;
}
.vt-settings-container .tax-select-field > label.fee-name-label-settings {
  margin-top: 5px;
}


.bottomLogo { 
  text-align: center;
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 8px 0;
}

.sidebarMenu {
  max-height: calc(100% - 300px);
  overflow-y: auto;
}

.userName p {
  color: inherit;
}

.min-wrap {
  max-width: 950px;
  margin: 0 auto;
}
.min-wrap ._loading_overlay_wrapper.css-79elbk .terminal-inner {
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.bordered {
  border: 1px solid #ececec;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .banner-heading {
      font-size: 40px !important;
  }
}
@media (max-width:567px){
  .logo {
		width: 100%;
		text-align: center;
	}
}
.custom-select .MuiInputBase-input {
  height: 2.2rem;
}
.custom-select .MuiInputBase-input p{
  /* top:5px; */
  margin: 0;
  position: absolute;
  left: 2px;
}

/* Start 03-13-2020 */
.dashboard-table tr .MuiSvgIcon-root{
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
.MuiTableRow-root:hover .MuiSvgIcon-root{
  visibility: visible;
  opacity: 1;
}
/* .MuiInput-underline div.MuiInputBase-input{height: 40px;} */
/* .MuiInput-underline div.MuiInputBase-input p {
  line-height: 4;
} */
.invoice-table tr td:first-child{
  min-width: 170px;
}

.TypableSelectDetails-valueContainer-540 .css-1df318c-Input{
/* position: absolute; */
}
.terminal-select >div>div>div>div>div>div p +div{
  position: absolute;
  margin: 0;
}

.MuiGrid-item label.MuiInputLabel-formControl{
  z-index: 0;
}

.termContentsPage{
  width: 70%;
  margin-top: 25px;
}

.termContentsPage h1{
  color: #1e4661;
}

.termContentsPage h2{
  margin-bottom: 15px;
  color: 1e4661;
}

.termContentsPage p{
  line-height: 26px;
  text-align: justify;
}

._loading_overlay_wrapper.css-79elbk{
  padding: 38px 0 !important;
  margin-top: 30px  !important;
  box-shadow: 1px 1px 9px #d4d4d4 !important;
}

body{
  background: #f6f6f79c !important;
}


.email-invoice{
  background: #e27329 !important;
  color: #fff;
 }

 .emailInvoiceTerminal {
  margin-left: 10px !important;
}
.btnRed {
  background-color: #FF0000 !important;
  color: #fff !important;
}



.product-table tr td:first-child {
  min-width: 240px;
}

.addInvoiceCustomePage {
  padding: 10px;
}
 
/*Start Invoice table responsive */

@media (max-width: 768px) {
  /* Force table to not be like tables anymore */
	table.invoice-table, .invoice-table thead,.invoice-table tbody, .invoice-table th, .invoice-table td, .invoice-table tr { 
		display: block; 
	}
  
  table.invoice-table ,.invoice-table tr{    
    margin-bottom: 15px;
  }
	/* Hide table headers (but not display: none;, for accessibility) */
	.invoice-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.invoice-table tr { border: 1px solid #ccc; }
	
	.invoice-table td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
    padding-left: 40%; 
    width: 100% !important;
	}
	
	.invoice-table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 33%;
		left: 6px;
		width: 25%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
  }
  .invoice-table td .MuiFormControl-marginNormal{
    text-align: right
  }
  .invoice-table td .MuiFormControl-marginNormal input {
    text-align: right;
    padding-right: 5px;
  }
	.invoice-table td .MuiInputBase-root{font-size: 1rem !important}
	/*
	Label the data
	*/
	.invoice-table td:nth-of-type(1):before { content: "Item"; }
	.invoice-table td:nth-of-type(2):before { content: "Description"; }
	.invoice-table td:nth-of-type(3):before { content: "Unit Cost"; }
	.invoice-table td:nth-of-type(4):before { content: "Quantity"; }
	.invoice-table td:nth-of-type(5):before { content: "Line Unit"; }
	.invoice-table tr{
    text-align: center;
  }
  .invoice-table tr .MuiSvgIcon-root{
    margin: 15px 20px 15px 20px;
    visibility: visible;
    opacity: 1;
  }

  .termContentsPage {
    width: 90%;
  }

  
.emailInvoiceTerminal{
  width: 100%;
  margin-bottom: 12px !important;
}


.emailInvoiceTerminal {
  margin-left: 0px !important;
}

}

/*End Invoice table responsive */

/*End 03-13-2020 */

.dateFilter-table{
  float: right;
  padding: 0px 3px 0px 1px;
  margin-top: -12px;
}

.top-seciton  {
  display: inline-block;
  width: 100%;
}
.top-seciton > div{
  width: 50%;
  float: left;
  padding: 0 10px;
}
.top-seciton .MuiToolbar-root > div:nth-child(2){
  flex: 0 !important;
}
.top-seciton .dateFilter-table {
  float: right;
  margin-top: 2px;
}
.top-seciton .MuiFormControl-root { 
  width: 41%;
  margin: 0 10px;
}
.top-seciton .MuiToolbar-root > div:first-child {
  width: 168px;
}
.top-seciton .MuiToolbar-root > div:first-child h6 {
  line-height: 1.2;
  text-align: left;
}

/* .custom-liststyle .MuiFormControl-root {
  margin-top: 0.75rem !important;
  margin-bottom: 8px;
}
.custom-liststyle .MuiInputBase-input > div:first-child {
  margin-top: -10px;
}*/
.custom-liststyle p {
  text-align: left;
  /* top: -29px; */
}
.MTableToolbar-actions-422 {
  color: rgba(0, 0, 0, 0.54);
  width: 150px;
}

.top-seciton .MuiToolbar-root > div:nth-child(4){  
  width: 370px;
}
.MuiInputBase-input > div:first-child {
  /* margin-top: -8px; */
}

@media (max-width: 1110px) {
  .top-seciton .MuiFormControl-root {
    width: 95%;
}
}

@media (max-width: 1050px) {
  .top-seciton > div {
    width: 100%;
    float: left;
    padding: 0 10px;
}
.top-seciton .MuiFormControl-root {
  width: 45%;
}
}

@media (max-width: 600px) {
  .top-seciton .MuiFormControl-root {
    width: 100%;
    margin: 20px 0;   
}
.top-seciton > div {
  width: 100%;
  display: inline-block;
  text-align: center;
}
.top-seciton .MuiToolbar-root > div:first-child {
  width: 100%;
}.top-seciton .MuiToolbar-root > div:nth-child(4) {
  width: 100%;
}
.top-seciton .MuiToolbar-root > div:first-child h6 {
  text-align: center;
}
}




/* 27-05-2020 */
.top-logo-panel{  
  background: #fff;
  display: inline-block;
  padding: 5px 10px;
}
.error-quote-message{
  margin: 20px 0;
  font-size: 20px;
  color: #000;
}
.top-logo-panel img{    
  width: 70px;
  float: left;
}
.top-logo-panel h3{
  float: right;
  color: #311f9e;
  font-size: 20px;
  padding: 16px 20px;
}

.MuiTypography-body1 {
  /* text-align: justify; */
}
.payment-info {
  width: 70%;
}


.payment-auth-container{
  background: #311f9e;
  height: 100%;
}
.payment-auth-container > div.css-79elbk {
  background: transparent !important;
  padding: 38px 30px !important;
  box-shadow: none !important;
}
.terminal-container {
  /* height: 661px; */
}

.msg-container {
  padding: 30px 20px !important;
  width: 50%;
}
.msg-container p {
  color: #000;
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
}


.MuiGrid-spacing-xs-3 {
  margin: -11px!important;
}
#DemoForm label{
  display: block;
  margin-bottom:2px;
}
#DemoForm input{
  border:1px solid #e3e3e3!important;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  margin: 2px 0px;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 0 12px;
}
.btn.DeclinedBtn {
  background: #e45e5e;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.btn.RequestedBtn {
  background : rgb(243, 243, 73);
  border: 0;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;

}
.navGrid
 {  
   display: flex;
 }
 .mr2{
   margin-right: 2px !important;
 }

 .ml5{
  margin-left: 5px !important;
}

.warning-display {
  background: rgb(255, 244, 229);
  margin-bottom: 20px !important;
}


.timer-wrapper {
  position: relative;
}
.timer-wrapper a.cancel-icon {
  position:absolute;
  top:0px;
  right:0px;
  color: #000;
}
.warning-display .MuiAlert-root {
  padding:0 16px;
}

