	body .forgotPassword {
	  background:url('./../../assets/images/loginImage.jpg');
	  width: 100%;
      height: 100%;
      position: absolute;
	}
	.paper {
	   margin-top: 100px;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   background-color:white;
	   padding:20px;
	   border-radius:5px;
	}
    .avatar {
	    margin: 1px;
    }
    .form {
		width: 100%;
		margin-top: 1px;
	}
	.text_right{
		text-align: right;
	}
		
	.green{
		color: forestgreen
	} 
	.red{
		color: red;
	}
	.check-icon{
		color: forestgreen;
		font-size: 12px;
		size: 10px;
	}
	.pswfield{
		position: relative;
		margin-bottom: 25px;
		width: auto;
		height: auto;
	}
	.instruction-section{   
		border-radius: 0 0 7px 7px;
		width: 100%;
		position: absolute;
		top: 0;
		opacity: 1;
		z-index: 1;
		padding: 6px 11px;
		background-color: white;
	// 	box-shadow: 3px 1px 11px 2px rgb(0 0 0 );
	// 	-webkit-box-shadow: 3px 1px 11px 2px rgb(0 0 0 );
	// 	-moz-box-shadow: 3px 1px 11px 2px rgb(0 0 0 );
	}
	.instructionsview {
		display: block;
		width: 100%;
		float: left;
	}
		
	@media(min-width:600px){
		.ErrorMessage_errors__20g1F{
		  width: 50%;
		}	
		.max_Width{
			max-width: 500px !important;
		}
	}