.termLogo{
  margin-left: 36.3rem;
}
.companyName{
  padding: 4px 10px;
  color: #0f7bc1;
  font-size: 24px;
  font-weight: 500;
}

._loading_overlay_wrapper .MuiGrid-justify-xs-center {
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
}

.MuiGrid-justify-xs-center div{
   box-shadow: none;
}

._loading_overlay_wrapper .MuiGrid-item{
  text-align: center;
}
