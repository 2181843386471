.pt_1{padding-top: 1rem;}
.ml_2
  {
      margin-left: 1rem !important;
  }

  .hide{
    display: none;
    text-align: center;
}
.show{
  display: block;
  text-align: center;
}
 