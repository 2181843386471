* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  /* background: #dedede; */
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.MuiButton-root{
  text-transform: none !important;
}
.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.error-pay {
  background-color:#fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tpMainContentB .wht_box {
    background: #fff;
    padding: 30px;      
}
.nav_width_65 .tpMainContentB{
	width: calc(100% - 175px); 
  margin-left: 175px;  
}
.tpMainContentB {  
	margin-left: 40px;
	width: calc(100% - 40px);    
	transition: all .3s;
  margin-top: 40px;
  padding: 30px; 
}
.MuiTablePagination-root:last-child {
  text-align: center;
}
.tpInvoiceEditM .MuiFormControl-root {
    display: flex;
    padding-right: 10px;
}
.tpInvoiceEditM .tpInvoiceHistory {
    margin-top: 16px;
    padding-left: 1rem;
}
.tpInvoiceEditM .tpInvoiceHistory .MuiInput-root {
    margin-right: 0 !important;
}
.ml_2 .MuiInputLabel-formControl {
  margin-left: 1rem;
}
.tpInvoiceEditM {
  margin-top: 0;
}
@media (max-width: 768px) {
  .MuiTablePagination-root .MuiTablePagination-toolbar {
    height: auto;
    display: block;
    padding: 0;
  }
	.tpMainContentB{  
		margin-left: 40px !important;
		width: calc(100% - 40px) !important;   
	}	
}
.dashboard-table thead tr .MuiTableCell-alignRight{
  text-align: left !important;
  color: #fff;
}

.dashboard-table{
  margin-top: 25px;
}

.dashboard-table thead tr {
  background: #666;
}

.dashboard-table thead tr .MuiTableCell-alignRight:nth-child(2){
  width: 60%;
}

.dashboard-table tbody tr .MuiTableCell-alignRight:nth-child(2){
  width: 60%;
  text-align: left;
}

.dashboard-table tbody tr .MuiTableCell-alignRight:nth-child(2) .MuiFormControl-marginNormal{
  width: 100%;
}

.dashboard-table thead tr .MuiTableCell-alignRight:last-child{
  white-space: nowrap;
}

.dashboard-table tbody tr .MuiTableCell-alignRight:last-child{
  white-space: nowrap;
}

.sub-field{
  position: absolute !important;
  top: -22px;
  width: 63% !important;
  right: 10px;
}

.sub-field1{
  position: absolute !important;
  top: -22px;
  left: -13px;
}

.sub-total .MuiGrid-root{
  position: relative;
}

.sub-total{
  margin-top: 25px;
}

.sub-total .MuiGrid-root.MuiGrid-grid-xs-5{
  text-align: right;
}

.sub-total .MuiGrid-root{
  margin: 0;
  max-width: initial;
}

.sub-total .MuiGrid-root.MuiGrid-grid-xs-7:last-child{
  font-weight: 600;
}

.sub-total .MuiGrid-root.MuiGrid-grid-xs-5:last-child{
  font-weight: 600;
}

._loading_overlay_wrapper.css-79elbk{
  background: #fff;
  padding: 15px;
  margin-top: 18px;
  box-shadow: 4px 3px 7px #ccc;
}

.MuiContainer-root {
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
}

.MuiGrid-item .makeStyles-root-71 .MuiBottomNavigationAction-root{
  padding: 6px 8px 8px;
}
@media (max-width:767px){
  [class*='tableWrapper'] .dashboard-table thead tr .MuiTableCell-alignRight{
    min-width:100px;
  }
}
@media (max-width:599px){
  .roleName {
    font-size: 14px;
  }
  .userName .MuiButton-label p {
      font-size: 12px;
  }
}