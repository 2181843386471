.rightPanel ._loading_overlay_wrapper {
    padding: 10px !important;
}
.green{
    color: forestgreen
} 
.red{
    color: red;
}
.check-icon{
    color: forestgreen;
    font-size: 12px;
    size: 10px;
}
.pswfield{
    position: relative;
    margin-bottom: 25px;
    width: auto;
    height: auto;
}
.instruction-section{   
    border-radius: 0 0 7px 7px;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 1;
    z-index: 1;
    padding: 6px 11px;
    background-color: white;
    box-shadow: 3px 1px 11px 2px rgb(0 0 0 / 22%);
    -webkit-box-shadow: 3px 1px 11px 2px rgb(0 0 0 / 22%);
    -moz-box-shadow: 3px 1px 11px 2px rgb(0 0 0 / 22%);
}
.instructionsview {
    display: block;
    width: 100%;
    float: left;
}
.marginpsw{
    /* margin:20px ; */
}
@media only screen and (max-width:767px){
    .custom-main button {
        float: none;
        margin: 10px 2px !important;
        padding: 10px 14px;
    }
    
    .custom-main {
        width: 100%;
    max-width: 100% !important;
    flex: 100% !important;
    padding: 10px !important;
    text-align: center;
    }
    .res-cus-center {
        text-align: center;
        margin: 20px 0px;
    }

}