@keyframes fade-in {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.errors {
    background-color: #FC3F56;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;    
    box-shadow: 2px 2px 7px 0 rgba(0,0,0,0.50);
    animation: fade-in .5s;
    font-family: Roboto;
    font-weight: bold;
	width:20rem;
	display:flex;
	align-self:center;
	margin: auto;
    justify-content: center;
    align-items: center;
	margin-top:20px;
}