.nav_width_65{
    width: 100%;
}
.ml_2
{
    margin-left: 1rem !important;
}
.emailInvoice{ 
    margin-right: 1rem !important;
    background-color: #e27329 !important;
 }
.mr_2{
    margin-right: 1rem
}
.cursorPointer{
    cursor: pointer;
}
.buttonMargin{
    margin-right: 5px !important;
}
 



//  [class*='TypableSelectDetails-input']{
//      height:2rem !important;
//  }
//  [class*='TypableSelectDetails-paper']{
//     z-index:9 !important;
// }
// [class*='TypableSelectDetails-placeholder']{
//     font-size: 1em !important;
//     padding-bottom: 3px !important;
// }