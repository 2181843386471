.Appheader {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* color: black; */
  }
  .App {
    text-align: center;
  } 
/*------ request-page ---*/

.requestformpage .adminBtn{
    background: #4CAF50 !important;
}

.requestformpage{
    background: #fafafa !important;
}
.requestformpage .MuiPaper-root{
    margin-bottom: 60px !important;
}
.requestformpage .MuiPaper-root{
    margin-bottom: 60px !important;
    
}

.requestformpage .saveforLetterBtn{
    background: #4CAF50 !important;
}
.requestformpage .saveforLetterBtn{
    background: #4CAF50 !important;
    color:#000 !important;
}

.requestformpage .sendPayment{
    background: #9c27b0 !important;
}


 
.top-header {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-top: 15px;
}
.logout-img{
    width:18px;
    height: 18px;
    margin-left:0px;
    margin-top:6px;
    float: right;
}
.topheader {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-top: 15px;
}
.logoutimg{
    width:18px;
    height: 18px;
    margin-left:0px;
    margin-top:6px;
    float: right;
}
.MuiButton-containedSecondary{
    background-color: #4CAF50 !important;
  }
  .containerControl{
      max-width: 980px;
      width:100%;
      margin-bottom: 50px;
  }
  .table{
    background-color: #4CAF50 ;
  }
  .newClass{
    background-color: #4CAF50 ;
  }
  .ml_2
  {
      margin-left: 1rem !important;
  }
 
  .Underline{
    color :blue;
    cursor:pointer
}
 .addNewClient{
    margin-top: 10px !important;
    background-color: #117CC1 !important;
 }
 .submit{ 
    background-color: #117CC1 !important;
 }
 .saveDraft{ 
    margin-left: 1rem !important;
    background-color: #adb2b5 !important;
 }
 .emailInvoice{ 
    margin-right: 1rem !important;
    background-color: #e27329 !important;
 }

 .itemHead{min-width:110px;}
 .pt6{padding-top: 2.5rem;}
 .unitCost3{width: 5rem  !important;}

 