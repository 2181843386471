@media only screen and (max-width:480px){
    .custom-styling-res {
        position: absolute;
        right: 0;
        top: 45px;
    }
    .contentPart {
        padding: 1rem;
        margin-top: 40px;
    }
    
}
@media only screen and (max-width: 767px)
{
.sidebar {
    width: 220px;
    height: 100%;
    position: absolute;
    z-index: 1;
    padding-top: 50px;
}
body.toggle .footer-padd {
    padding-left: 50px;
}
body.toggle .contentPart {
    margin-left: 54px;
}
body.toggle .sidebar .logoBox img {
    width: 50px;
    height: auto;
}
._loading_overlay_wrapper.css-79elbk {
    padding: 0 !important;
    background: none !important;
    box-shadow: none !important;
  }
  .contentPart main.MuiContainer-root.terminal-container {
    padding: 0px;
}
.tp-tabcustom div#vertical-tabpanel-0 .MuiBox-root {
    padding: 0 !important;
}
.custom-spacing {
    margin: 20px 0px 0px !Important;
}
.contentPart main.MuiContainer-root {
    padding: 0px;
}
.vt-settings-container .discount-label-field > label {
  
    font-size: 12px;
}
.custom-name-classes .MuiBox-root {
    padding: 10px 0;
}
.top-seciton {
    display: inline-block;
    width: 100%;
    padding: 20px 10px;
}
.MuiGrid-root.custom-class {
    margin: 20px 0px;
}

.MuiGrid-root.custom-class button {
    margin: 20px 0px 0px;
}
}