	body .login {
	  width: 100%;
	  height: 100%;
      position: absolute;
	}
	.paper {
	   margin-top: 100px;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   background-color:#eaf0f1;
	   padding:20px;
	   border-radius:5px;
	}
    .avatar {
	    margin: 1px;
    }
    .form {
		width: 100%;
		margin-top: 1px;
	}
	.submit{
		width: 100%; 
	}

	.forget{
		width:40%;
		float: left;
		margin: 5px 0 0 0 !important;
	}

	.back{
		width:20%;
		float: right;
		text-align: right;
		margin: 5px 0 0 0 !important;
	}